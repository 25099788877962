import { useTranslation } from "react-i18next";

import packageInfo from "../../../../../package.json";
import { getCurrentYear } from "utils/utils";
import { User } from "types/types";

import AvatarImage from "components/molecules/avatar-image/AvatarImage";

import { ReactComponent as UserIcon } from "assets/icons/profile-user.svg";

import {
  AvatarContainer,
  CompanyDeptContainer,
  DepartmentsList,
  CompanyName,
  Label,
  Loader,
  Row,
  Shimmer,
  UserDetails,
  Value,
} from "../../styles/profile.styles";
import Flags from "components/molecules/flags/Flags";
import ErrorAlert from "components/molecules/ErrorAlert";

const Placeholder = ({ width }: { width: number }) => (
  <Loader width={width}>
    <Shimmer />
  </Loader>
);

type UserInfoProps = {
  user: User | undefined;
  isError: boolean;
  isLoading: boolean;
  isVisible: boolean;
  isChecklistInUse: boolean;
  onVersionClick: () => void;
  onDepartmentsClick: () => void;
  permittedToUpdateAll: boolean;
  permittedToReadCompany: boolean;
  permittedToReadDeptName: boolean;
};

function UserInfo({
  user,
  isError,
  isLoading,
  isVisible,
  isChecklistInUse,
  onVersionClick,
  onDepartmentsClick,
  permittedToUpdateAll,
  permittedToReadCompany,
  permittedToReadDeptName,
}: UserInfoProps) {
  const { t } = useTranslation("common");

  let userAvatar = null;
  let companyDept = null;

  // --------------- Content ---------------

  if (user && user.avatar) {
    const { avatar } = user;
    const avatarId = typeof avatar === "string" ? avatar : avatar.id;

    userAvatar = <AvatarImage avatarId={avatarId} large />;
  } else {
    userAvatar = <UserIcon className='user-icon' />;
  }

  if (user) {
    if ("Department" in user) {
      const { Department } = user;

      if (Department) {
        const companyName = Department.Company ? Department.Company.CompanyName : "";
        const deptName = Department.DepartmentName ?? "";

        companyDept = (
          <CompanyDeptContainer onClick={onDepartmentsClick}>
            {permittedToReadCompany && <CompanyName>{companyName}</CompanyName>}
            {permittedToReadDeptName && (
              <DepartmentsList>
                <Value smallFont>{deptName}</Value>
              </DepartmentsList>
            )}
          </CompanyDeptContainer>
        );
      }
    }

    if ("Departments" in user) {
      const { Departments } = user;

      if (Departments && Departments.length) {
        let companyName = "";

        if (Departments[0].Departments_id) {
          companyName = Departments[0].Departments_id.Company
            ? Departments[0].Departments_id.Company.CompanyName
            : "";
        }

        companyDept = (
          <CompanyDeptContainer onClick={onDepartmentsClick}>
            {permittedToReadCompany && <CompanyName>{companyName}</CompanyName>}
            {permittedToReadDeptName && (
              <DepartmentsList>
                {Departments.map(
                  ({ Departments_id }, index) =>
                    Departments_id && (
                      <Value key={index} smallFont>
                        {Departments_id.DepartmentName}
                      </Value>
                    ),
                )}
              </DepartmentsList>
            )}
          </CompanyDeptContainer>
        );
      }
    }
  }

  return isVisible ? (
    <>
      {!isError ? (
        <UserDetails>
          <AvatarContainer>{userAvatar}</AvatarContainer>
          <Row noBorder alignRight>
            {!isLoading ? (
              <Value>
                {user?.first_name}&nbsp;{user?.last_name}
              </Value>
            ) : (
              <Placeholder width={16} />
            )}
          </Row>

          <Row alignRight noBorder noPadding>
            {!isLoading ? (
              <Value smallFont>{user?.role.name}</Value>
            ) : (
              <Placeholder width={11} />
            )}
          </Row>

          <Row alignRight>
            {!isLoading ? (
              <Value email smallFont>
                {user?.email}
              </Value>
            ) : (
              <Placeholder width={18} />
            )}
          </Row>

          <Row
            alignRight
            clickable={permittedToUpdateAll && !isChecklistInUse}
            noBorder={!permittedToReadCompany && !permittedToReadDeptName}
            noPadding={!permittedToReadCompany && !permittedToReadDeptName}
          >
            {!isLoading ? <>{companyDept}</> : <Placeholder width={14} />}
          </Row>

          <Row alignRight>
            <Flags />
          </Row>
        </UserDetails>
      ) : (
        <ErrorAlert message={t("error.unable-to-load-user-data")} smallMargin />
      )}

      <Row noBorder>
        <Label onClick={onVersionClick}>v.{packageInfo.version}</Label>
        <Value className='copyright-container'>
          <span>&#xa9;&nbsp;</span>
          <span className='year'>{getCurrentYear()}</span>
        </Value>
      </Row>
    </>
  ) : null;
}

export default UserInfo;
