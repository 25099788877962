import { getTime } from "date-fns";

import { User } from "types/types";
import { RoleNames } from "types/roles.types";
import { CreatedTask } from "types/tasks.types";
import { getRoleName } from "contexts/user-context/utils/user-context.utils";

const { MANAGER, ADMINISTRATOR, LEADER } = RoleNames;

export const sortByDateDesc = (data: CreatedTask[]) =>
  data
    .map((item) => ({
      ...item,
      date_created: getTime(new Date(item.date_created)),
    }))
    .sort((a, b) => b.date_created - a.date_created);

export const getTabsVisibleFlag = (user: User | undefined) => {
  if (!user) return false;

  const roleName = getRoleName(user.role.id);

  return [ADMINISTRATOR, MANAGER, LEADER].some((role) => role === roleName);
};

export const clearSessionStorage = () => {
  ["selectedTaskReturnPath", "selectedTask", "taskRelated"].forEach((item) => {
    sessionStorage.removeItem(item);
  });
};
