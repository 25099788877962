import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let error = {
    number_too_large: "Number too large!",
    empty_field: "Field cannot be empty!",
  };

  let label = {
    btn: {
      save_next: "Next",
      skip: "Skip",
    },
  };

  let modal = {
    message: "Are you sure you want to delete the answer?",
    btnLabel: "Delete",
  };

  if (isPolish) {
    error = {
      number_too_large: "Za duża wartość!",
      empty_field: "Pole nie może być puste!",
    };

    modal = {
      message: "Czy na pewno chcesz skasować odpowiedź?",
      btnLabel: "Skasuj",
    };

    label = {
      btn: {
        save_next: "Dalej",
        skip: "Pomiń",
      },
    };
  }

  return { error, modal, label };
};
