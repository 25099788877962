import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import { useQuery } from "react-query";
import { getCookie } from "react-use-cookie";
import { useLocation } from "react-router-dom";

import { ACCESS_TOKEN, getCatchErrorMessage, isObjectEmpty } from "utils/utils";
import useIndexedDB from "hooks/useIndexedDB";
import useQueryString from "hooks/useQueryString";
import useBackArrowHandler from "hooks/useBackArrowHandler";

import { getQuestionnaires } from "api/questionnaires";
import { getAccidentForms } from "api/accidentForms";

import {
  Af,
  SelectedQuestionnaire,
  AccidentForm,
  Answer,
  MachineProcess,
  QuestionnaireObject,
  QuestionnairesResponse,
  Stores,
} from "types/types";
import { ActionType, QuestionnairesContextType } from "./types";
import AuthContext from "../auth-context/AuthContext";
import { reducer, initialState } from "./reducers";

const QuestionnairesContext = createContext<QuestionnairesContextType>({
  state: initialState,
  setQuestionnairesAction: () => {},
  setSelectedQuestionnaireAction: () => {},
  setStartTimeAction: () => {},
  setAccidentFormsAction: () => {},
  setSelectedAccidentFormAction: () => {},
  setAccidentStartTimeAction: () => {},
  setAnswersAction: () => {},
  setAccidentAnswersAction: () => {},
  setMachineProcessAction: () => {},
  questionnairesLoading: false,
  questionnairesError: false,
  fetchQuestionnaires: () => {},
  accidentFormsLoading: false,
  accidentFormsError: false,
  fetchAccidentForms: () => {},
  clearWholeStateAction: () => {},
  clearQuestionnaireStateAction: () => {},
  serialNumberPath: "",
  removeAccidentsQuery: () => {},
});

const { SerialNumbers } = Stores;

export function QuestionnairesContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { authenticated, qrTokenStatus, isQrLogin } = useContext(AuthContext);
  const [enabledAf, setEnabledAf] = useState(false);
  const { pathname } = useLocation();

  const query = useQueryString();
  const { handleItemInDB } = useIndexedDB(SerialNumbers);

  const token = getCookie(ACCESS_TOKEN);
  const serialNo = query.get("SerialNo") ?? "";

  const serialNumberPath = state.serialNumber
    ? `/check-lists?SerialNo=${state.serialNumber}`
    : "";

  const enabledQr =
    authenticated &&
    !!serialNo &&
    ["/questionnaire", "/check-lists"].some((path) => pathname.includes(path));

  // --------------- Set state actions ---------------

  const clearWholeStateAction = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_WHOLE_STATE });
  }, [dispatch]);

  const clearQuestionnaireStateAction = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_QUESTIONNAIRE_STATE });
  }, [dispatch]);

  const setQuestionnairesAction = useCallback(
    (questionnaires: QuestionnaireObject[]) =>
      dispatch({ type: ActionType.SET_QUESTIONNAIRES, payload: questionnaires }),
    [dispatch],
  );

  const setSerialNumberAction = useCallback(
    (serialNumber: string) =>
      dispatch({ type: ActionType.SET_SERIAL_NUMBER, payload: serialNumber }),
    [dispatch],
  );

  const setMachineProcessAction = useCallback(
    (machineProcess: MachineProcess) =>
      dispatch({ type: ActionType.SET_MACHINE_PROCESS, payload: machineProcess }),
    [dispatch],
  );

  const setSelectedQuestionnaireAction = useCallback(
    (selectedQuestionnaire: SelectedQuestionnaire) =>
      dispatch({
        type: ActionType.SET_SELECTED_QUESTIONNAIRE,
        payload: selectedQuestionnaire,
      }),
    [dispatch],
  );

  const setStartTimeAction = useCallback(
    (startTime: Date) =>
      dispatch({ type: ActionType.SET_START_TIME, payload: startTime }),
    [dispatch],
  );

  const setAccidentFormsAction = useCallback(
    (accidentForms: AccidentForm[]) =>
      dispatch({ type: ActionType.SET_ACCIDENT_FORMS, payload: accidentForms }),
    [dispatch],
  );

  const setSelectedAccidentFormAction = useCallback(
    (selectedAccidentForm: Af) =>
      dispatch({
        type: ActionType.SET_SELECTED_ACCIDENT_FORM,
        payload: selectedAccidentForm,
      }),
    [dispatch],
  );

  const setAccidentStartTimeAction = useCallback(
    (accidentStartTime: Date) =>
      dispatch({ type: ActionType.SET_ACCIDENT_START_TIME, payload: accidentStartTime }),
    [dispatch],
  );

  const setAnswersAction = useCallback(
    (answers: Answer[]) => dispatch({ type: ActionType.SET_ANSWERS, payload: answers }),
    [dispatch],
  );

  const setAccidentAnswersAction = useCallback(
    (accidentAnswers: Answer[]) =>
      dispatch({ type: ActionType.SET_ACCIDENT_ANSWERS, payload: accidentAnswers }),
    [dispatch],
  );

  // --------------- Back arrow click handler ---------------

  useBackArrowHandler(state.selectedQuestionnaire, pathname);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (isQrLogin) {
      setEnabledAf(authenticated && qrTokenStatus === "valid");
    } else {
      setEnabledAf(authenticated);
    }
  }, [authenticated, qrTokenStatus, isQrLogin]);

  // --------------- Setting data in local storage and IndexedDB ---------------

  useEffect(() => {
    // SETTING SERIAL NUMBER IN LOCAL STORAGE
    if (state.serialNumber && !localStorage.getItem("serialNumber")) {
      localStorage.setItem("serialNumber", serialNo);
    }

    // SETTING ANSWERS IN LOCAL STORAGE
    if (pathname.includes("/questionnaire")) {
      try {
        localStorage.setItem("answers", JSON.stringify(state.answers));
        dispatchEvent(new StorageEvent("storage"));
      } catch (err) {
        // const message = `[Setting answers] - ${getCatchErrorMessage(err)}`;
        // alert(message);
      }
    }

    // SETTING ACCIDENT ANSWERS IN LOCAL STORAGE
    if (pathname.includes("/accident-form")) {
      try {
        localStorage.setItem("accidentAnswers", JSON.stringify(state.accidentAnswers));
        dispatchEvent(new StorageEvent("storage"));
      } catch (err) {
        const message = `[Setting accident answers] - ${getCatchErrorMessage(err)}`;

        alert(message);
      }
    }

    // SETTING MACHINE/PROCESS IN LOCAL STORAGE
    if (pathname.includes("/check-lists")) {
      if (state.machineProcess) {
        localStorage.setItem("machineProcess", JSON.stringify(state.machineProcess));
      }
    }

    // SETTING SELECTED QUESTIONNAIRE IN LOCAL STORAGE
    if (state.selectedQuestionnaire && !localStorage.getItem("selectedQuestionnaire")) {
      localStorage.setItem(
        "selectedQuestionnaire",
        JSON.stringify(state.selectedQuestionnaire),
      );
    }

    // SETTING SELECTED ACCIDENT FORM IN LOCAL STORAGE
    if (state.selectedAccidentForm && !localStorage.getItem("selectedAccidentForm")) {
      localStorage.setItem(
        "selectedAccidentForm",
        JSON.stringify(state.selectedAccidentForm),
      );
    }
  }, [state, serialNo, pathname]);

  // --------------- API ---------------

  // ***** Fetch questionnaires *****
  const {
    isFetching: questionnairesLoading,
    isError: questionnairesError,
    refetch,
  } = useQuery("questionnaires", getQuestionnaires(token, serialNo), {
    onSuccess: async ({ data }: QuestionnairesResponse) => {
      const { questionnaires, machine: Machine, process: Process } = data;
      const isProperSerialNumber = !isObjectEmpty(Machine) || !isObjectEmpty(Process);

      setQuestionnairesAction(questionnaires);
      setMachineProcessAction({ Machine, Process });

      if (isProperSerialNumber) {
        let SerialNumberFromPayload = "";

        if (Machine) {
          SerialNumberFromPayload = Machine.SerialNo;
        }
        if (Process) {
          SerialNumberFromPayload = Process.SerialNo;
        }

        // SnapBug does not return SerialNo,
        // so the serialNo from payload has to be used instead.
        const serialNumber = SerialNumberFromPayload ? SerialNumberFromPayload : serialNo;

        setSerialNumberAction(serialNumber);
        handleItemInDB(serialNumber);
      }
    },
    enabled: enabledQr,
    retry: false,
  });

  // ***** Fetch accident forms *****
  const {
    isLoading: accidentFormsLoading,
    isError: accidentFormsError,
    refetch: refetchAccidentForms,
    remove: removeAccidentsQuery,
  } = useQuery("accidentForms", getAccidentForms(token), {
    onSuccess: setAccidentFormsAction,
    enabled: enabledAf,
    retry: false,
  });

  const fetchQuestionnaires = () => {
    refetch();
  };

  const fetchAccidentForms = () => {
    refetchAccidentForms();
  };

  const value = {
    state,
    setQuestionnairesAction,
    setSelectedQuestionnaireAction,
    setStartTimeAction,
    setAccidentFormsAction,
    setSelectedAccidentFormAction,
    setAccidentStartTimeAction,
    setAnswersAction,
    setAccidentAnswersAction,
    setMachineProcessAction,
    questionnairesLoading,
    questionnairesError,
    fetchQuestionnaires,
    accidentFormsLoading,
    accidentFormsError,
    fetchAccidentForms,
    clearWholeStateAction,
    clearQuestionnaireStateAction,
    serialNumberPath,
    removeAccidentsQuery,
  };

  return (
    <QuestionnairesContext.Provider value={value}>
      {children}
    </QuestionnairesContext.Provider>
  );
}

export default QuestionnairesContext;
