import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { QUESTION_TYPE_IDS, Answers } from "types/types";
import { PromptType, TextPromptProps } from "./types/prompt.types";
import { getPromptTranslations } from "./translations/prompt.translations";
import useAnimate from "hooks/useAnimate";

import { ReactComponent as PenIcon } from "assets/icons/edit_square.svg";

import PromptContainer from "./components/prompt-container/PromptContainer";

import { IconContainer, Label } from "./styles/prompt.styles";

const { YES_NO, YES_NO_TEXT, YES_NO_PHOTO, TEXT, NUMERIC, FILE_PHOTO } =
  QUESTION_TYPE_IDS;
const { NO } = Answers;

function TextPrompt({
  onClick,
  questionType,
  answerToCurrentQuestion,
  descriptionMissing,
}: TextPromptProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { animate, duration } = useAnimate();

  const {
    i18n: { language },
  } = useTranslation();
  const { label } = getPromptTranslations(PromptType.TEXT, language);

  useEffect(() => {
    if (answerToCurrentQuestion?.ReactionValue) {
      setIsVisible(false);
      return;
    }

    switch (questionType) {
      case YES_NO:
      case TEXT:
      case NUMERIC:
        setIsVisible(false);
        break;
      case YES_NO_TEXT:
      case YES_NO_PHOTO:
        const visible = answerToCurrentQuestion?.Value === NO;
        setIsVisible(visible);

        break;
      case FILE_PHOTO:
        setIsVisible(true);
    }
  }, [
    answerToCurrentQuestion?.ReactionValue,
    answerToCurrentQuestion?.Value,
    questionType,
  ]);

  return isVisible ? (
    <PromptContainer
      onClick={onClick}
      animate={animate}
      duration={duration}
      className='text-prompt'
      shaking={descriptionMissing}
    >
      <IconContainer isRed={descriptionMissing}>
        <PenIcon className='icon' />
      </IconContainer>
      <Label isRed={descriptionMissing}>{label}</Label>
    </PromptContainer>
  ) : null;
}

export default TextPrompt;
