import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

function useAutosave(textAreaValue: string | undefined) {
  const [saved, setSaved] = useState(false);
  const params = useParams();
  const previousValue = useRef<string>("");

  const isTyping =
    !!textAreaValue && !!previousValue.current && textAreaValue !== previousValue.current;

  const clickingBlocked = isTyping || saved;

  const handleStopTyping = useCallback(() => {
    if (previousValue.current === textAreaValue || !textAreaValue) return;

    setSaved(true);
    previousValue.current = textAreaValue;
  }, [textAreaValue]);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    previousValue.current = "";
  }, [params.question]);

  useEffect(() => {
    // At first render textAreaValue has value of undefined,
    // hence this condition.
    if (textAreaValue === undefined || previousValue.current) return;

    previousValue.current = textAreaValue;
  }, [textAreaValue]);

  useEffect(() => {
    const timer = setTimeout(handleStopTyping, 1000);

    return () => clearTimeout(timer);
  }, [textAreaValue, handleStopTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (saved) {
        setSaved(false);
      }
    }, 1200);

    return () => clearTimeout(timer);
  }, [saved]);

  return { saved, isTyping, clickingBlocked };
}

export default useAutosave;
