import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import Camera, { DEVICE, FACING_MODE, PLACEMENT } from "react-camera-ios";
import "react-camera-ios/build/styles.css";

import useScreen from "hooks/useScreen";
import { isMobile, PORTRAIT } from "utils/utils";

import BackArrow from "components/atoms/BackArrow";
import Button from "components/atoms/Button";

import { ContentContainer, Header } from "styles/generalStyles";

const CameraContainer = styled.div`
  max-width: 45rem;
  height: 50vh;

  position: relative;
  background-color: ${({ theme }) => theme.greyDark};
  margin: 0 auto;
`;

const PhotoContainerIPhone = styled.div`
  position: relative;
  margin: 0 auto;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Actions = styled.div<{
  isPhotoTaken: boolean;
  isMobilePortrait: boolean;
  accidentView?: boolean;
}>`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ accidentView, isPhotoTaken }) =>
    accidentView &&
    isPhotoTaken &&
    css`
      bottom: -1.7rem;
    `}
`;

const ButtonContainer = styled.div`
  width: 30%;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 100%;
  min-height: 10rem;
  border: 4px solid ${({ theme }) => theme.errorColor};
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  color: #fff;
  padding: 1rem;
  z-index: ${({ theme }) => theme.level2};

  .name {
    font-size: 1.4rem;
  }

  .message,
  .constraint {
    margin-top: 1rem;
  }

  .close-btn {
    float: right;
    padding: 0.5rem 1rem;
    font-family: GothamBook;
    background-color: ${({ theme }) => theme.greyLight};
    font-size: 1.4rem;
  }
`;

const containerStyle = { display: "flex", height: "100%", width: "100%" };
const facingModeiOS = isMobile ? FACING_MODE.ENVIRONMENT : FACING_MODE.USER;

type UserMediaError = {
  name: string;
  message: string;
  constraint: string;
};

function ReactCameraIos() {
  const [imgSrc, setImgSrc] = useState("");
  const isPhotoTaken = !!imgSrc;

  const [userMediaError, setUserMediaError] = useState<UserMediaError | undefined>();

  const { orientation } = useScreen();

  const navigate = useNavigate();

  const isMobilePortrait = isMobile && orientation === PORTRAIT;

  const onUserMediaError = (err: DOMException | string) => {
    let constraint = "";

    if (typeof err !== "string") {
      const { name, message } = err;

      if ("constraint" in err) {
        constraint = err["constraint"] as string;
      }

      setUserMediaError({ name, message, constraint });
    } else {
      setUserMediaError({ name: "", message: err, constraint });
    }
  };

  const onTakePhotoIPhone = (dataUrl: string) => {
    setImgSrc(dataUrl);
    // stopStream();
  };

  const removePhoto = () => {
    setImgSrc("");
  };

  const clearUserMediaError = () => {
    setUserMediaError(undefined);
  };

  const onCloseButtonClick = () => {
    clearUserMediaError();
  };

  return (
    <ContentContainer>
      <Header standardFont>
        <BackArrow
          onClick={() => {
            navigate(-1);
          }}
        />
        <span>react-camera-ios</span>
      </Header>

      {isPhotoTaken && (
        <PhotoContainerIPhone>
          <Photo src={imgSrc} alt='' />
        </PhotoContainerIPhone>
      )}

      {!isPhotoTaken && (
        <CameraContainer>
          <div style={containerStyle}>
            <Camera
              device={DEVICE.MOBILE}
              facingMode={facingModeiOS}
              placement={PLACEMENT.COVER}
              quality='1'
              onError={onUserMediaError}
              onTakePhoto={onTakePhotoIPhone}
            />
          </div>
        </CameraContainer>
      )}

      {userMediaError && (
        <ErrorContainer>
          {userMediaError?.name && <div className='name'>{userMediaError?.name}</div>}
          {userMediaError?.message && (
            <div className='message'>{userMediaError?.message}</div>
          )}
          {userMediaError?.constraint && (
            <div className='constraint'>{userMediaError?.constraint}</div>
          )}
          <button className='close-btn' onClick={onCloseButtonClick}>
            ZAMKNIJ
          </button>
        </ErrorContainer>
      )}

      <Actions
        isPhotoTaken={isPhotoTaken}
        isMobilePortrait={isMobilePortrait}
        // accidentView={accidentView}
      >
        {isPhotoTaken && (
          <ButtonContainer>
            <Button label='Usuń' onClick={removePhoto} />
          </ButtonContainer>
        )}
      </Actions>
    </ContentContainer>
  );
}

export default ReactCameraIos;
