import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  QUESTION_TYPE_IDS,
  Answers,
  CountryCodes,
  Answer as AnswerType,
} from "types/types";
import { NumberOfPhotos, PhotoPreview } from "../../types/tableOfContents.types";

import Thumb from "components/atoms/Thumb";
import PhotosInfo from "./components/photos-info/PhotosInfo";
import MissingAlert from "./components/missing-alert/MissingAlert";

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 1.2rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon-yes {
    height: 2rem;

    path {
      fill: ${({ theme }) => theme.yesButton};
    }
  }

  .icon-no {
    height: 2rem;
    margin-left: 1rem;

    path {
      fill: ${({ theme }) => theme.noButton};
    }
  }
`;

const AnswerValue = styled.div`
  ${({ theme }) => theme.ellipsisMultiline};
  -webkit-line-clamp: 1;
  max-width: 100%;

  .numeric {
    font-size: 1.8rem;
  }

  .select {
    font-size: 1.4rem;
  }

  .file-answer,
  .yes-no-photo-answer {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .file-answer {
    padding-bottom: 0.35rem;
  }

  .image-thumb-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .align {
    transform: translateY(0.2rem);
  }
`;

const NoAnswer = styled.div`
  font-style: italic;
  opacity: 0.6;
  font-size: 1.3rem;
`;

const NaLabel = styled.span`
  font-size: 1.3rem;
  font-family: GothamBold;
  color: ${({ theme }) => theme.primary_300};
`;

const DescriptionPreview = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 93%;
  padding: 0 1rem 1.1rem 1rem;
  font-size: 1.4rem;
  font-style: italic;
  ${({ theme }) => theme.ellipsis}
`;

const { YES_NO_PHOTO, YES_NO_TEXT, TEXT, FILE_PHOTO, NUMERIC, SELECT } =
  QUESTION_TYPE_IDS;
const { YES, NO } = Answers;

const ThumbIcon = ({ up = false, down = false }: { up?: boolean; down?: boolean }) => {
  const customClass = up ? "icon-yes" : "icon-no";

  return <Thumb up={up} down={down} filled customClass={customClass} />;
};

type AnswerProps = {
  answer: AnswerType | undefined;
  numberOfPhotos: NumberOfPhotos;
  minPhotos: number | null;
  photoPreview: PhotoPreview;
  required: boolean;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = {
    na: "Not Applicable",
    photos: "Photos",
    missing: "Missing",
  };
  let no_description = "No Description";

  if (isPolish) {
    label = {
      na: "Nie Dotyczy",
      photos: "Zdjęcia",
      missing: "Brakuje",
    };

    no_description = "No Description";
  }

  return { label, no_description };
};

function Answer({
  answer,
  numberOfPhotos,
  minPhotos,
  photoPreview,
  required,
}: AnswerProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation("table-of-contents");
  const {
    label: { na, ...labels },
    no_description,
  } = getTranslations(language);

  let answerValue = null;

  if (answer) {
    const { QuestionType, Value, ReactionValue } = answer;

    switch (QuestionType) {
      case TEXT:
        answerValue = <DescriptionPreview>{Value}</DescriptionPreview>;
        break;
      case NUMERIC:
        answerValue = <span className='numeric'>{Value}</span>;
        break;
      case SELECT:
        answerValue = <span className='select'>{Value}</span>;
        break;

      case YES_NO_TEXT:
        answerValue = <NaLabel>{na}</NaLabel>;

        if (Value === YES) {
          answerValue = <ThumbIcon up />;
        }

        if (Value === NO) {
          answerValue = ReactionValue ? (
            <div>
              <DescriptionPreview>{ReactionValue}</DescriptionPreview>
              <ThumbIcon down />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {required && <MissingAlert label={no_description} />}
              <ThumbIcon down />
            </div>
          );
        }

        break;

      case YES_NO_PHOTO:
        if (Value === YES) {
          answerValue = <ThumbIcon up />;
        }

        if (Value === NO) {
          answerValue = (
            <div className='yes-no-photo-answer'>
              <PhotosInfo
                labels={labels}
                minPhotos={minPhotos as number}
                numberOfPhotos={numberOfPhotos ? numberOfPhotos : 0}
                photoPreview={photoPreview as string}
                required={required}
              />

              <div className='image-thumb-container'>
                <ThumbIcon down />
              </div>
            </div>
          );
        }
        break;

      case FILE_PHOTO:
        answerValue = (
          <div className='file-answer'>
            <PhotosInfo
              labels={labels}
              minPhotos={minPhotos as number}
              numberOfPhotos={numberOfPhotos ? numberOfPhotos : 0}
              photoPreview={photoPreview as string}
              required={required}
            />
          </div>
        );

        break;

      default:
        answerValue = <NaLabel>{na}</NaLabel>;
        if (Value === YES) answerValue = <ThumbIcon up />;
        if (Value === NO) answerValue = <ThumbIcon down />;
    }
  }

  return (
    <Container>
      {!!answer ? (
        <AnswerValue>{answerValue}</AnswerValue>
      ) : (
        <NoAnswer>{t("no-answer")}</NoAnswer>
      )}
    </Container>
  );
}

export default Answer;
