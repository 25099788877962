import { useContext } from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import AuthContext from "contexts/auth-context/AuthContext";
import useNetworkStatus from "hooks/useNetworkStatus";
import { getTopBarTranslations } from "./translations/topBar.translations";
import { environment } from "utils/environment.utils";
import { Environments } from "types/environment.types";

import { ReactComponent as OfflineIcon } from "assets/icons/offline.svg";

import SnapCheckLogo from "../../atoms/logos/SnapCheckLogo";
import UserAvatar from "./components/UserAvatar";
import Flags from "components/molecules/flags/Flags";

const Container = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.topBarHeightLow};
  padding: 0 1.5rem;
  background-color: ${({ theme }) => theme.white};
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.level2};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: height 1s ease-in-out;

  @media screen and (min-height: 616px) {
    height: ${({ theme }) => theme.topBarHeight};
  }
`;

const OfflineAlert = styled.div`
  background-color: ${({ theme }) => theme.babyYellow};
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8);

  display: flex;
  align-items: center;

  .offline-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
  }

  animation: showAlert 0.3s ease-out forwards;

  @media screen and (min-height: 616px) {
    transform: translateX(-50%) scale(1);
  }

  @keyframes showAlert {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const StyledToast = styled(ToastContainer)`
  font-size: 2rem;
`;

const { PRODUCTION } = Environments;

function TopBar() {
  const { authenticated } = useContext(AuthContext);
  const { pathname } = useLocation();
  const {
    i18n: { language },
  } = useTranslation("common");

  const { isOnline } = useNetworkStatus();

  const { alert } = getTopBarTranslations(language);

  const userAvatarVisible =
    pathname !== "/forgot-password" &&
    !pathname.includes("login") &&
    !pathname.includes("/reset-password") &&
    !pathname.includes("/invite") &&
    !pathname.includes("/apk-update-info") &&
    authenticated;

  const onLogoClick = () => {
    // window.location.reload();
    if (!pathname.includes("/login")) return;
    if (!environment[PRODUCTION]) return;
  };

  // --------------- Effect handlers ---------------

  return (
    <>
      <Container>
        {!isOnline && (
          <OfflineAlert>
            <OfflineIcon className='offline-icon' />
            <span>{alert.offline}</span>
          </OfflineAlert>
        )}
        <SnapCheckLogo onClick={onLogoClick} />
        {userAvatarVisible && <UserAvatar />}

        {pathname.includes("login") && <Flags paddingRight />}
      </Container>

      <StyledToast position='top-left' theme='colored' autoClose={5000} />
    </>
  );
}

export default TopBar;
