import styled from "styled-components/macro";

import AnimatedArrow from "../button-label/components/animated-arrow/AnimatedArrow";

const Container = styled.div`
  position: relative;
  text-align: center;

  .arrow {
    right: -1rem;
  }
`;

const Label = styled.div`
  font-size: 1.8rem;
`;

function NextBtnLabel({ label, disabled }: { label: string; disabled?: boolean }) {
  return (
    <Container>
      <Label>{label}</Label>
      {!disabled && <AnimatedArrow customClass='arrow' />}
    </Container>
  );
}

export default NextBtnLabel;
