import { createContext, ReactNode, useCallback, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { instance } from "axios-instance/axios-instance";
import { getCookie } from "react-use-cookie";
import jwt_decode from "jwt-decode";

import { ACCESS_TOKEN } from "utils/utils";
import { authReducer, init } from "./reducer";
import { ActionType, QrTokenStatus } from "./types";
import useServiceWorker from "hooks/useServiceWorker";

type Token = {
  admin_access: boolean;
  app_access: boolean;
  exp: number;
  iat: number;
  id: string;
  iss: string;
  role: string;
};

type AuthContextType = {
  clearAuthState: () => void;
  authenticated: boolean;
  setAuthenticated: (arg: boolean) => void;
  shouldReAuth: boolean;
  setShouldReAuth: (arg: boolean) => void;
  redirectPath: string;
  setRedirectPath: (arg: string) => void;
  inviteEmail: string;
  setInviteEmail: (arg: string) => void;
  qrTokenStatus: "valid" | "invalid" | null;
  setQrTokenStatus: (arg: QrTokenStatus) => void;
  isQrLogin: boolean;
};

const AuthContext = createContext<AuthContextType>({
  clearAuthState: () => {},
  authenticated: false,
  setAuthenticated: () => {},
  shouldReAuth: false,
  setShouldReAuth: () => {},
  redirectPath: "",
  setRedirectPath: () => {},
  inviteEmail: "",
  setInviteEmail: () => {},
  qrTokenStatus: null,
  setQrTokenStatus: () => {},
  isQrLogin: false,
});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  useServiceWorker(pathname);

  const token = getCookie(ACCESS_TOKEN);
  const initialAuth = !!token;

  const [state, dispatch] = useReducer(authReducer, initialAuth, init);

  const isQrLogin = pathname.includes("/qr-login");

  // --------------- Set state actions ---------------

  const clearAuthState = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_AUTH_STATE });
  }, [dispatch]);

  const setAuthenticated = useCallback(
    (payload: boolean) => {
      dispatch({ type: ActionType.SET_AUTHENTICATED, payload });
    },
    [dispatch],
  );

  const setShouldReAuth = useCallback(
    (payload: boolean) => {
      dispatch({ type: ActionType.SET_SHOULD_REAUTH, payload });
    },
    [dispatch],
  );

  const setRedirectPath = useCallback(
    (payload: string) => {
      dispatch({ type: ActionType.SET_REDIRECT_PATH, payload });
    },
    [dispatch],
  );

  const setInviteEmail = useCallback(
    (payload: string) => {
      dispatch({ type: ActionType.SET_INVITE_EMAIL, payload });
    },
    [dispatch],
  );

  const setQrTokenStatus = useCallback(
    (payload: QrTokenStatus) => {
      dispatch({ type: ActionType.SET_QR_TOKEN_STATUS, payload });
    },
    [dispatch],
  );

  // --------------- Interceptors ---------------
  useEffect(() => {
    const jwtRegex = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/gm;
    const isJWT = jwtRegex.test(token);

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // if (
        //   error.response.status === 401 &&
        //   !error.response.config.url.includes("/auth")
        // ) {
        //   // ????
        // }
        return Promise.reject(error);
      },
    );

    if (token && isJWT) {
      const decodedJwt: Token = jwt_decode(token);
      const sessionExpired = decodedJwt.exp * 1000 < Date.now();

      if (sessionExpired) {
        if (!(pathname === "/login" || pathname === "/forgot-password")) {
          setShouldReAuth(true);
        }
      }
    }
  }, [pathname, token, setShouldReAuth]);

  const value = {
    ...state,
    clearAuthState,
    setAuthenticated,
    setShouldReAuth,
    setRedirectPath,
    setInviteEmail,
    setQrTokenStatus,
    isQrLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
