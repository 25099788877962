import { useEffect, useRef } from "react";
import styled from "styled-components/macro";

import { isMobile } from "utils/utils";
import useModal from "hooks/useModal";
import useAccidentForm from "hooks/useAccidentForm";
import useClickOutside from "hooks/useClickOutside";

import { Asterisk } from "styles/generalStyles";
import { Question, SubText } from "../styles/accidenFormStyles";

const Container = styled.div`
  padding-top: 1rem;
  margin-bottom: 2rem;
  user-select: none;
`;

function QuestionTitle({ required }: { required: boolean }) {
  const { currentQuestion } = useAccidentForm();
  const { open, openModal, closeModal } = useModal();
  const timeoutId = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));
  const ref = useRef<HTMLDivElement>(null);

  const question = currentQuestion?.QuestionText as string;
  const subtext = currentQuestion?.QuestionSubtext as string;

  useClickOutside(ref, closeModal);

  const onClick = () => {
    openModal();
  };

  useEffect(() => {
    if (open) {
      timeoutId.current = setTimeout(closeModal, 2000);
    } else {
      clearTimeout(timeoutId.current);
    }
  }, [open, closeModal]);

  return (
    <Container>
      <Question>
        {question && question.trim()}

        {required && (
          <Asterisk onClick={onClick} infoVisible={open} isMobile={isMobile} />
        )}
      </Question>

      {!!subtext && <SubText dangerouslySetInnerHTML={{ __html: subtext }} />}
    </Container>
  );
}

export default QuestionTitle;
