import { useCallback, useEffect } from "react";

import useTimeout from "hooks/useTimeout";
import useInterval from "hooks/useInterval";
import useNotification from "hooks/useNotification";
import { isIOS, reloadPage } from "utils/utils";

const getSWRegistration = () => {
  return navigator.serviceWorker.getRegistration();
};

function useServiceWorker(pathname: string) {
  const notify = useNotification();

  const setTimeOut = useTimeout();
  const delay = isIOS ? 2000 : 1000;

  const handleUpdate = useCallback(
    (registration: ServiceWorkerRegistration) => {
      if (registration && registration.waiting) {
        try {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });

          setTimeOut(() => {
            reloadPage();
          }, delay);
        } catch (e) {
          notify("Błąd aktualizacji", "error");
        }
      }
    },
    [delay, notify, setTimeOut],
  );

  const checkIfUpdateAvailable = useCallback(() => {
    getSWRegistration().then((registration) => {
      const shouldHandleUpdate =
        registration?.waiting &&
        ["/login", "/home"].some((path) => pathname.includes(path));

      if (shouldHandleUpdate) {
        handleUpdate(registration);
      }
    });
  }, [handleUpdate, pathname]);

  useInterval(() => {
    checkIfUpdateAvailable();
  }, 1000 * 60 * 1);

  useEffect(() => {
    setTimeOut(() => {
      checkIfUpdateAvailable();
    }, 6000);
  }, [checkIfUpdateAvailable, setTimeOut]);

  // !!!!! REMOVE THIS IN VERSION > 5.8.0 !!!!!
  useEffect(() => {
    localStorage.removeItem("updateDeferred");
  }, []);
}

export default useServiceWorker;
