import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import Webcam from "react-webcam";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import { isMobile, PORTRAIT } from "utils/utils";
import useScreen from "hooks/useScreen";
import useDevices from "hooks/useDevices";

import BackArrow from "components/atoms/BackArrow";
import Button from "components/atoms/Button";
import { ReactComponent as CameraSwitchIcon } from "assets/icons/video-switch.svg";
import { ReactComponent as CameraIcon } from "assets/icons/photo-camera.svg";

import { ContentContainer, Header } from "styles/generalStyles";

const CameraContainer = styled.div`
  max-width: 45rem;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.greyDark};

  margin: 0 auto;
`;

const CameraSwitch = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.3rem;
  z-index: ${({ theme }) => theme.level1};
  cursor: pointer;
`;

const PhotoContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  margin: 0 auto;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Actions = styled.div<{
  isPhotoTaken: boolean;
  isMobilePortrait: boolean;
  accidentView?: boolean;
}>`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ accidentView, isPhotoTaken }) =>
    accidentView &&
    isPhotoTaken &&
    css`
      bottom: -1.7rem;
    `}
`;

const TakePhotoCircle = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    width: 7rem;
    height: 100%;
    display: block;
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.white};
    background-color: rgba(179, 198, 219, 0.2);
  }

  .camera {
    width: 3.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: ${({ theme }) => theme.white};
  }
`;

const ButtonContainer = styled.div`
  width: 40%;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 100%;
  min-height: 10rem;
  border: 4px solid ${({ theme }) => theme.errorColor};
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  color: #fff;
  padding: 1rem;
  z-index: ${({ theme }) => theme.level2};

  .name {
    font-size: 1.4rem;
  }

  .message,
  .constraint {
    margin-top: 1rem;
  }

  .close-btn {
    float: right;
    padding: 0.5rem 1rem;
    font-family: GothamBook;
    background-color: ${({ theme }) => theme.greyLight};
    font-size: 1.4rem;
  }
`;

type UserMediaError = {
  name: string;
  message: string;
  constraint: string;
};

type FacingMode = { exact: "user" | "environment" };

const facingModeInitial: FacingMode = {
  exact: isMobile ? "environment" : "user",
};

function ReactWebcam() {
  const [imgSrc, setImgSrc] = useState("");
  const isPhotoTaken = !!imgSrc;
  const [facingMode, setFacingMode] = useState<FacingMode>(facingModeInitial);
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const [userMediaError, setUserMediaError] = useState<UserMediaError>();
  const navigate = useNavigate();

  const { isVideoInput } = useDevices();
  const { orientation } = useScreen();

  const webcamRef = useRef<Webcam>(null);
  const pictureRef = useRef(null);

  const isMobilePortrait = isMobile && orientation === PORTRAIT;

  const videoConstraints = {
    facingMode,
  };

  const takePhoto = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      if (imageSrc) {
        setImgSrc(imageSrc);
      }
    }
  }, [webcamRef, setImgSrc]);

  const removePhoto = () => {
    setImgSrc("");
  };

  const clearUserMediaError = () => {
    setUserMediaError(undefined);
  };

  const onUserMedia = (stream: MediaStream) => {
    if (stream.active) {
      if (userMediaError) {
        clearUserMediaError();
      }
    }
  };

  const onUserMediaError = (err: DOMException | string) => {
    let constraint = "";

    if (typeof err !== "string") {
      const { name, message } = err;

      if ("constraint" in err) {
        constraint = err["constraint"] as string;
      }

      setUserMediaError({ name, message, constraint });
    } else {
      setUserMediaError({ name: "", message: err, constraint });
    }
  };

  const toggleFacingMode = () => {
    if (facingMode.exact === "user") {
      setFacingMode({ exact: "environment" });
    } else {
      setFacingMode({ exact: "user" });
    }
  };

  const onCloseButtonClick = () => {
    // clearUserMediaError();

    window.location.reload();
  };

  useEffect(() => {
    if (isVideoInput && webcamRef.current) {
      const { video } = webcamRef.current;
      let width = 0;
      let height = 0;

      if (isMobilePortrait) {
        if (video) {
          width = video.clientWidth;
          height = video.clientHeight * 2;
        }
      } else {
        if (video) {
          width = video.clientWidth;
          height = video.clientHeight;
        }
      }

      setVideoSize({ height, width });
    }
  }, [isVideoInput, isMobilePortrait]);

  const onUpdate = useCallback(
    ({ x, y, scale }: { x: number; y: number; scale: number }) => {
      const { current: photoEl } = webcamRef;
      // check if image exists
      if (photoEl) {
        const value = make3dTransformValue({ x, y, scale });

        if (photoEl.video) {
          photoEl.video.style.setProperty("transform", value);
        }
      }
    },
    [],
  );

  return (
    <ContentContainer>
      <Header standardFont>
        <BackArrow
          onClick={() => {
            navigate(-1);
          }}
        />
        <span>react-webcam</span>
      </Header>

      {isPhotoTaken && (
        <PhotoContainer width={videoSize.width} height={videoSize.height}>
          <Photo src={imgSrc} alt='' />
        </PhotoContainer>
      )}

      {!isPhotoTaken && (
        <CameraContainer>
          {isMobile && (
            <CameraSwitch onClick={toggleFacingMode}>
              <CameraSwitchIcon width='3rem' height='3rem' />
            </CameraSwitch>
          )}

          <QuickPinchZoom onUpdate={onUpdate}>
            <Webcam
              audio={false}
              ref={webcamRef}
              height='100%'
              width='100%'
              screenshotFormat='image/jpeg'
              videoConstraints={videoConstraints}
              onUserMedia={onUserMedia}
              onUserMediaError={onUserMediaError}
            />
          </QuickPinchZoom>

          {userMediaError && (
            <ErrorContainer>
              {userMediaError?.name && <div className='name'>{userMediaError?.name}</div>}
              {userMediaError?.message && (
                <div className='message'>{userMediaError?.message}</div>
              )}
              {userMediaError?.constraint && (
                <div className='constraint'>{userMediaError?.constraint}</div>
              )}
              <button className='close-btn' onClick={onCloseButtonClick}>
                ZAMKNIJ
              </button>
            </ErrorContainer>
          )}
        </CameraContainer>
      )}

      <Actions
        isPhotoTaken={isPhotoTaken}
        isMobilePortrait={isMobilePortrait}
        // accidentView={accidentView}
      >
        {!isPhotoTaken ? (
          <TakePhotoCircle onClick={takePhoto}>
            <CameraIcon className='camera' />
          </TakePhotoCircle>
        ) : (
          <ButtonContainer>
            <Button label='Usuń' onClick={removePhoto} />
          </ButtonContainer>
        )}
      </Actions>
    </ContentContainer>
  );
}

export default ReactWebcam;
