import { useEffect, useState } from "react";

const DEVICE_UUID = "device-uuid";

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const getDeviceUuid = () => localStorage.getItem(DEVICE_UUID) ?? generateUUID();

function useDeviceUuid() {
  const [deviceUuid] = useState(getDeviceUuid());

  useEffect(() => {
    localStorage.setItem(DEVICE_UUID, deviceUuid);
  }, [deviceUuid]);

  return { deviceUuid };
}

export default useDeviceUuid;
