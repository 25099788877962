import { useContext, useRef, useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UserContext from "contexts/user-context/UserContext";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import useQuestionnaire from "hooks/useQuestionnaire";
import useAccidentForm from "hooks/useAccidentForm";
import useClickOutside from "hooks/useClickOutside";
import useTimeout from "hooks/useTimeout";
import useLogout from "hooks/useLogout";
import useReturnPaths from "hooks/useReturnPaths";
import useMachineProcessInfo from "hooks/useMachineProcessInfo";
import { getReturnPath, isMobile } from "utils/utils";
import { getTranslations } from "pages/user-tasks/translations/tasks.translations";
import { getCommonTranslations } from "translations/common.translations";
import { ReturnPaths } from "types/return-paths.types";

import { ReactComponent as AlarmIcon } from "assets/icons/alarm.svg";
import { ReactComponent as InfoIcon } from "assets/icons/information-o.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/logout.svg";
import { ReactComponent as ExitIcon } from "assets/icons/exit.svg";
import { ReactComponent as ListIcon } from "assets/icons/list-solid.svg";
import { ReactComponent as UserIcon } from "assets/icons/profile-user.svg";
import { ReactComponent as TasksIcon } from "assets/icons/handyman.svg";

import ConfirmationModal from "components/organisms/ConfirmationModal";

const Container = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1000;
`;

const Overlay = styled.div<{
  show: boolean;
  hide: boolean;
  isMobile: boolean;
}>`
  position: absolute;
  top: 5rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ isMobile }) =>
    isMobile ? "rgba(0, 0, 0, 60%)" : "rgba(0, 0, 0, 15%)"};
  overflow: hidden;

  @media screen and (min-height: 616px) {
    top: 6.1rem;
  }

  ${({ show }) =>
    show &&
    css`
      animation: showOverlay 0.4s;
      @keyframes showOverlay {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    `}

  ${({ hide }) =>
    hide &&
    css`
      animation: hideOverlay 0.4s;
      @keyframes hideOverlay {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    `}
`;

const NavBar = styled.div<{ show: boolean; hide: boolean }>`
  min-width: 25rem;
  position: absolute;
  top: 5.1rem;
  right: -23rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  box-shadow: -1px 2px 5px -1px rgba(0, 0, 0, 0.7);

  @media screen and (min-height: 616px) {
    top: 6.1rem;
  }

  ${({ show, hide }) =>
    show &&
    !hide &&
    css`
      animation: slideIn 0.4s forwards;

      @keyframes slideIn {
        100% {
          right: 0;
        }
      }
    `}

  ${({ hide }) =>
    hide &&
    css`
      animation: slideOut 0.3s;

      @keyframes slideOut {
        0% {
          right: 0;
        }

        100% {
          right: -23rem;
        }
      }
    `}
`;

const Menu = styled.ul``;

const MenuItem = styled.li<{ underline?: boolean }>`
  height: 5.8rem;
  padding-left: 1.7rem;
  padding-right: 2rem;
  font-weight: 700;
  position: relative;
  background-color: ${({ theme }) => theme.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: filter 0.2s ease-out;
  max-width: 35rem;

  @media screen and (min-height: 600px) {
    height: 8rem;
  }

  &:hover {
    filter: brightness(0.95);
  }

  ${({ underline }) =>
    underline &&
    css`
      &::after {
        content: "";
        position: absolute;
        width: 90%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background-color: ${({ theme }) => theme.primary_300};
      }
    `}

  &:last-child {
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

const IconContainer = styled.div`
  flex-basis: 18%;

  display: flex;
  align-items: center;

  .icon {
    width: 2.2rem;
    height: 2.2rem;
  }

  .alarm {
    fill: ${({ theme }) => theme.alarmColor};
  }

  .profile {
    fill: ${({ theme }) => theme.primary};
  }

  .exit {
    width: 3rem;
    height: 3rem;
    transform: scaleX(-1) translateX(0.5rem);

    path {
      stroke: ${({ theme }) => theme.primary};
    }
  }

  .list {
    width: 2.7rem;
    height: 2.7rem;

    path {
      stroke: ${({ theme }) => theme.primary};
    }
  }

  .tasks {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

const Label = styled.span<{ accident?: boolean }>`
  flex-basis: 80%;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: ${({ accident, theme }) => (!accident ? theme.primary : theme.alarmColor)};
  ${({ theme }) => theme.ellipsisMultiline}
`;

const { ALARMS_RETURN_PATH, PROFILE_RETURN_PATH, TASKS_RETURN_PATH } = ReturnPaths;

enum ModalType {
  EXIT = "exit",
  SIGN_OUT = "signOut",
}

const { EXIT, SIGN_OUT } = ModalType;

function SideNavbar() {
  const {
    toggleSideMenu,
    sideMenuOpen,
    PERMISSIONS: { TASKS_PERMISSIONS, MACHINES_PERMISSIONS, PROCESSES_PERMISSIONS },
  } = useContext(UserContext);
  const { serialNumberPath, state } = useContext(QuestionnairesContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [sideMenuHidden, setSideMenuHidden] = useState(false);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const ref = useRef(null);
  const modalType = useRef<ModalType>();

  const setTimeOut = useTimeout();
  const { handleLogOut } = useLogout();
  const { clearState } = useQuestionnaire();
  const { clearState: clearAccidentState } = useAccidentForm();
  const {
    t,
    i18n: { language },
  } = useTranslation("side-navbar");
  const { header } = getTranslations(language);
  const { modalMessage, button } = getCommonTranslations(language);

  const returnPath = getReturnPath();
  const currentPath = pathname + search;
  const { handleOpenPage, handleClosePage } = useReturnPaths();
  const { name: machineProcessName } = useMachineProcessInfo();

  const message = modalType.current === EXIT ? t("modal.message") : modalMessage.signout;
  const buttonLabel =
    modalType.current === EXIT ? t("modal.button-label") : button.label.signout;

  // --------------- PERMISSIONS ---------------

  const { machineProcess } = state;

  const hasPermissionToReadTasks =
    TASKS_PERMISSIONS.PERMITTED_TO_READ.ALL ||
    TASKS_PERMISSIONS.PERMITTED_TO_READ.Title ||
    TASKS_PERMISSIONS.PERMITTED_TO_READ.Description ||
    TASKS_PERMISSIONS.PERMITTED_TO_READ.status ||
    TASKS_PERMISSIONS.PERMITTED_TO_READ.AssignTo ||
    TASKS_PERMISSIONS.PERMITTED_TO_READ.user_created;

  let hasPermissionToReadInfo = false;

  if (machineProcess) {
    const { Machine, Process } = machineProcess;

    if (Machine) {
      hasPermissionToReadInfo =
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.MachineModel ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.Make ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.status ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.SerialNo ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.ServiceExpiryDate ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.HourMeter ||
        MACHINES_PERMISSIONS.PERMITTED_TO_READ.Misc;
    }

    if (Process) {
      hasPermissionToReadInfo =
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ALL ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ProcessName ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.SerialNo ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.status ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.ServiceExpiryDate ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.Address ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.Geolocation ||
        PROCESSES_PERMISSIONS.PERMITTED_TO_READ.Misc;
    }
  }

  // --------------- MENU ITEMS VISIBILITY FLAGS ---------------

  const isAlarmVisible =
    pathname !== "/home" &&
    !pathname.includes("/accident-form") &&
    !pathname.includes("/gallery") &&
    !!state.accidentForms?.length;

  const isTasksVisible = !pathname.includes("/task") && hasPermissionToReadTasks;

  const isProfileVisible =
    !pathname.includes("/profile") &&
    !pathname.includes("/accident-form") &&
    !pathname.includes("/device-info");

  const isInfoVisible =
    !pathname.includes("/home") &&
    !pathname.includes("/serial-number-method-selection") &&
    !pathname.includes("/machine-process-info") &&
    !pathname.includes("/profile") &&
    !pathname.includes("accident") &&
    !pathname.includes("task") &&
    !!machineProcessName &&
    hasPermissionToReadInfo;

  const isExitVisible =
    !pathname.includes("/home") &&
    !pathname.includes("/serial-number-method-selection") &&
    !pathname.includes("/check-lists") &&
    !pathname.includes("/tasks") &&
    !pathname.includes("/table-of-contents") &&
    !pathname.includes("/profile") &&
    !pathname.includes("/machine-process-info") &&
    !pathname.includes("/task");

  const isListVisible =
    !pathname.includes("/table-of-contents") && pathname.includes("/questionnaire");

  const hideSideMenu = () => {
    setSideMenuHidden(true);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const goToChecklists = () => {
    if (serialNumberPath) {
      navigate(serialNumberPath, { replace: true });
    } else {
      navigate("/home", { replace: true });
    }
  };

  const handleMenuItemClick = (returnPath: ReturnPaths) => {
    handleOpenPage(returnPath, currentPath);
    toggleSideMenu();
  };

  // --------------- On click handlers ---------------

  const handleClickOutside = () => {
    hideSideMenu();
  };

  useClickOutside(ref, handleClickOutside);

  const onListClick = () => {
    localStorage.setItem("tableOfContentsReturnPath", currentPath);
    toggleSideMenu();
    navigate("/table-of-contents");
  };

  const onTasksClick = () => {
    handleMenuItemClick(TASKS_RETURN_PATH);
  };

  const onAlarmClick = () => {
    handleMenuItemClick(ALARMS_RETURN_PATH);
  };

  const onInfoClick = () => {
    localStorage.setItem("infoReturnPath", currentPath);
    toggleSideMenu();
    navigate("/machine-process-info?tab=info");
  };

  const onProfileClick = () => {
    handleMenuItemClick(PROFILE_RETURN_PATH);
  };

  const onExitClick = () => {
    modalType.current = EXIT;

    toggleSideMenu();

    if (pathname === "/accident-forms-list") {
      handleClosePage(ALARMS_RETURN_PATH);
    } else if (pathname === "/profile") {
      handleClosePage(PROFILE_RETURN_PATH);
    } else {
      openConfirmationModal();
    }
  };

  const onSignOutClick = () => {
    modalType.current = SIGN_OUT;
    toggleSideMenu();

    if (pathname.includes("/home")) {
      handleLogOut();
    } else {
      openConfirmationModal();
    }
  };

  const handleExitQuestionnaire = () => {
    if (pathname.includes("/accident-form")) {
      clearAccidentState();
      closeConfirmationModal();

      if (returnPath === "/home") {
        navigate(returnPath, { replace: true });
      } else {
        navigate("/accident-forms-list", { replace: true });
      }
    } else {
      clearState();
      closeConfirmationModal();
      goToChecklists();
    }
  };

  const onConfirmationButtonClick = () => {
    if (modalType.current === EXIT) {
      handleExitQuestionnaire();
    }

    if (modalType.current === SIGN_OUT) {
      closeConfirmationModal();
      handleLogOut();
    }

    modalType.current = undefined;
  };

  const handleEscapeClick = useCallback(() => {
    if (sideMenuOpen) {
      hideSideMenu();
    }
  }, [sideMenuOpen]);

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (sideMenuHidden) {
      setTimeOut(() => {
        toggleSideMenu();
        setSideMenuHidden(false);
      }, 150);
    }
  }, [sideMenuHidden, setTimeOut, toggleSideMenu]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        handleEscapeClick();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleEscapeClick]);

  return (
    <>
      {sideMenuOpen && (
        <Container>
          <Overlay show={sideMenuOpen} hide={sideMenuHidden} isMobile={isMobile} />
          <NavBar ref={ref} show={sideMenuOpen} hide={sideMenuHidden}>
            <Menu>
              {!pathname.includes("/server-off-alert") && (
                <>
                  {isInfoVisible && (
                    <MenuItem onClick={onInfoClick} underline={!isListVisible}>
                      <IconContainer>
                        <InfoIcon className='icon' />
                      </IconContainer>
                      <Label>{machineProcessName}</Label>
                    </MenuItem>
                  )}

                  {isListVisible && (
                    <MenuItem onClick={onListClick}>
                      <IconContainer>
                        <ListIcon className='icon list' />
                      </IconContainer>
                      <Label>{t("list-of-questions")}</Label>
                    </MenuItem>
                  )}

                  {isExitVisible && (
                    <MenuItem onClick={onExitClick} underline>
                      <IconContainer>
                        <ExitIcon className='icon exit' />
                      </IconContainer>
                      <Label>{t("exit")}</Label>
                    </MenuItem>
                  )}

                  {isTasksVisible && (
                    <MenuItem onClick={onTasksClick}>
                      <IconContainer>
                        <TasksIcon className='icon tasks' />
                      </IconContainer>
                      <Label>{header.title}</Label>
                    </MenuItem>
                  )}

                  {isAlarmVisible && (
                    <MenuItem onClick={onAlarmClick}>
                      <IconContainer>
                        <AlarmIcon className='icon alarm' />
                      </IconContainer>
                      <Label accident>{t("alarms")}</Label>
                    </MenuItem>
                  )}

                  {isProfileVisible && (
                    <MenuItem onClick={onProfileClick} underline>
                      <IconContainer>
                        <UserIcon className='icon profile' />
                      </IconContainer>
                      <Label>{t("my-profile")}</Label>
                    </MenuItem>
                  )}
                </>
              )}
              <MenuItem onClick={onSignOutClick}>
                <IconContainer>
                  <LogOutIcon className='icon' />
                </IconContainer>
                <Label>{t("sign-out")}</Label>
              </MenuItem>
            </Menu>
          </NavBar>
        </Container>
      )}

      <ConfirmationModal
        message={message}
        onClick={onConfirmationButtonClick}
        onClose={closeConfirmationModal}
        open={confirmationModalOpen}
        buttonLabel={buttonLabel}
        buttonMiddle
      />
    </>
  );
}

export default SideNavbar;
