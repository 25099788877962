import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Orientation } from "types/types";
import { LANDSCAPE, PORTRAIT } from "utils/utils";

const maxHeight = "570px";

function useScreen() {
  const [orientation, setOrientation] = useState<Orientation>(PORTRAIT);

  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  const [viewPortWidth, setViewPortWidth] = useState(0);
  const [viewPortHeight, setViewPortHeight] = useState(0);

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia(`(max-height: ${maxHeight})`).matches,
  );

  const { pathname } = useLocation();
  const accidentView = pathname.includes("/accident-form");

  const setScreenAndViewPortSize = () => {
    const {
      screen: { width, height },
      innerWidth,
      innerHeight,
    } = window;

    if (width < height) {
      setOrientation(PORTRAIT);
    } else {
      setOrientation(LANDSCAPE);
    }

    setScreenWidth(width);
    setScreenHeight(height);

    setViewPortWidth(innerWidth);
    setViewPortHeight(innerHeight);
  };

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)");
    const smallScreen = window.matchMedia(`(max-height: ${maxHeight})`);

    setScreenAndViewPortSize();

    const onOrientationChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setOrientation(PORTRAIT);
      } else {
        setOrientation(LANDSCAPE);
      }

      setScreenAndViewPortSize();
    };

    const onHeightChange = (e: MediaQueryListEvent) => {
      setIsSmallScreen(e.matches);
    };

    portrait.addEventListener("change", onOrientationChange);
    smallScreen.addEventListener("change", onHeightChange);

    return () => {
      portrait.removeEventListener("change", onOrientationChange);
      smallScreen.removeEventListener("change", onHeightChange);
    };
  }, []);

  return {
    orientation,
    screenWidth,
    screenHeight,
    viewPortWidth,
    viewPortHeight,
    isSmallScreen,
    accidentView,
  };
}

export default useScreen;
