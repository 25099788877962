import { useEffect, useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Webcam from "react-webcam";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import useTimeout from "hooks/useTimeout";
import useScreen from "hooks/useScreen";
import useShutterSound from "hooks/useShutterSound";
import useDeviceAndAppInfo from "hooks/useDeviceAndAppInfo";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import { device, isMobile, isIOS, getCatchErrorMessage } from "utils/utils";
import { DeviceInfo, UserMediaError, FormType } from "types/types";

import { ReactComponent as TorchIcon } from "assets/icons/flashlight.svg";
import { ReactComponent as RotateIcon } from "assets/icons/rotate.svg";

// import NoCameraDetected from "../NoCameraDetected";
import PhotoLimitInfo from "../PhotoLimitInfo";

import {
  Container,
  CameraContainer,
  TorchToggle,
  ToolBar,
  RotateView,
  Scale,
} from "./styles/styles";
import BottomPanel from "./components/BottomPanel";
import ErrorAlert from "./components/ErrorAlert";
import PhotoNotTakenAlert from "./components/PhotoNotTakenAlert";

type WebcamCaptureProps = {
  createAnswer: (value?: string) => void;
  currentQuestionId: string | undefined;
  maxPhotos: number | undefined | null;
  handleCloseCameraView?: () => void;
  formType: FormType;
};

type Coordinates = {
  x: number;
  y: number;
  scale: number;
};

const FACING_MODES = { USER: "user", ENVIRONMENT: "environment" };

const { USER, ENVIRONMENT } = FACING_MODES;
const getFacingModeInitial = (isMobile: boolean) => (isMobile ? ENVIRONMENT : USER);

function WebcamCapture({
  createAnswer,
  currentQuestionId,
  maxPhotos,
  handleCloseCameraView,
  formType,
}: WebcamCaptureProps) {
  const [facingMode, setFacingMode] = useState(getFacingModeInitial(isMobile));
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [isTorchOn, setIsTorchOn] = useState(false);

  const [photoPreview, setPhotoPreview] = useState({ src: "", count: 0 });
  const [photoLimitVisible, setPhotoLimitVisible] = useState(false);
  const [userMediaError, setUserMediaError] = useState<UserMediaError>();
  const [photoNotTaken, setPhotoNotTaken] = useState(false);

  const [flash, setFlash] = useState(false);
  const [currentScale, setCurrentScale] = useState(0);
  const [scaleVisible, setScaleVisible] = useState(false);
  const { playShutterSound } = useShutterSound();

  const { getPhotosFromIndexedDB } = usePhotosObjectStore(formType);

  const webcamRef = useRef<Webcam>(null);
  const coordinatesRef = useRef<Coordinates>({ x: 0, y: 0, scale: 0 });
  const transformValueRef = useRef("");

  const { deviceInfo } = useDeviceAndAppInfo();

  const devInfo = deviceInfo as DeviceInfo;
  const isIOs = devInfo.os.name === "iOS";

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const setTimeOut = useTimeout();
  const { accidentView } = useScreen();

  const videoTrackRef = useRef<MediaStreamTrack>();

  const screenshotQuality = device.iPad ? 0.98 : 1;
  const photosLimitReached = photoPreview.count === maxPhotos;

  const videoConstraints = {
    facingMode,
  };

  const torchIconVisible =
    isMobile && !isIOs && facingMode === ENVIRONMENT && !userMediaError;

  let content = null;

  const turnTorchOn = () => {
    setIsTorchOn(true);
  };

  // This is to turn the torch off on devices where
  // setting the 'isTorchOn' flag to false does not work.
  // This happens on PM66 with Android Marshmallow.
  // ImageCapture object is not supported by iOS.
  const handleTurnTorchOff = async () => {
    if (isIOs || devInfo.os.versionName !== "Marshmallow") {
      return;
    }

    if (videoTrackRef.current) {
      try {
        const imageCapture = new ImageCapture(videoTrackRef.current);
        const capabilities = await imageCapture.getPhotoCapabilities();
        let imageBlob = new Blob();

        // Take a photo with the torch off.
        // This is a workaround to turn the torch off
        // after taking a photo in case the torch won't turn off.
        if (capabilities.fillLightMode) {
          imageCapture.takePhoto({ fillLightMode: "off" }).then((photo) => {
            imageBlob = photo;
            const objectURL = URL.createObjectURL(imageBlob);
            URL.revokeObjectURL(objectURL);
          });
        }
      } catch (err) {
        const message = `[ImageCapture] - ${getCatchErrorMessage(err)}`;
        alert(message);
      }
    }
  };

  const turnTorchOff = async () => {
    if (isTorchOn) {
      setIsTorchOn(false);
      handleTurnTorchOff();
    }
  };

  const handleTakePhotoAction = (src: string) => {
    setFlash(true);
    if (!isIOS) playShutterSound();
    createAnswer(src);
    setPhotoPreview((prev) => ({ src, count: prev.count + 1 }));
    turnTorchOff();
  };

  const toggleFacingMode = () => {
    if (videoTrackRef.current) {
      videoTrackRef.current.stop();
    }
    turnTorchOff();
    setIsCameraReady(false);

    if (facingMode === USER) {
      setFacingMode(ENVIRONMENT);
    } else {
      setFacingMode(USER);
    }
  };

  const toggleTorch = async () => {
    setIsTorchOn((prev) => !prev);

    if (isTorchOn) {
      turnTorchOff();
    } else {
      turnTorchOn();
    }
  };

  const clearCameraError = () => {
    setUserMediaError(undefined);
  };

  // --------------- On click handlers ---------------

  const onTakePhotoCircleClick = () => {
    if (!webcamRef.current) return;
    if (photosLimitReached) {
      setPhotoLimitVisible(true);
      return;
    }

    const { scale, x, y } = coordinatesRef.current;
    let imageSrc = "";

    // ----- If image ZOOMED -----
    if (scale > 1) {
      const { video } = webcamRef.current;
      if (!video) return;

      const videoWidth = video.clientWidth as number;
      const videoHeight = video.clientHeight as number;

      // *** 1. Create canvas
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = videoWidth;
      canvas.height = videoHeight;

      // *** 2. Put video in canvas to get a screenshot
      context.drawImage(video, x, y, videoWidth * scale, videoHeight * scale);

      // *** 3. Convert canvas to base64
      imageSrc = canvas.toDataURL("image/jpeg", 1.0);
    }

    // ----- If image NOT ZOOMED -----
    if (Math.ceil(scale) === 1) {
      imageSrc = webcamRef.current.getScreenshot() as string;
    }

    if (!imageSrc) {
      setPhotoNotTaken(true);
      return;
    }

    handleTakePhotoAction(imageSrc);

    // ---------------
  };

  const onCloseButtonClick = () => {
    clearCameraError();
    if (handleCloseCameraView) {
      handleCloseCameraView();
    }
  };

  const onPhotoPreviewClick = () => {
    if (photoPreview.count) {
      const photoIndex = photoPreview.count - 1;
      const formType = accidentView ? "accident" : "questionnaire";

      const galleryReturnPath = pathname + search;

      localStorage.setItem("galleryReturnPath", galleryReturnPath);

      navigate(
        `/gallery?formType=${formType}&currentQuestionId=${currentQuestionId}&photoIndex=${photoIndex}`,
      );
    }
  };

  // --------------- Media events handlers ---------------

  // const onCameraStart = () => {
  //   const SUPPORTS_MEDIA_DEVICES = "mediaDevices" in navigator;

  //   if (!SUPPORTS_MEDIA_DEVICES) return;

  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: {
  //         facingMode,
  //       },
  //     })
  //     .then((stream) => {
  //       const track = stream.getVideoTracks()[0];
  //       videoTrackRef.current = track;
  //     });

  //   setIsCameraReady(true);
  // };

  const onUserMedia = (stream: MediaStream) => {
    if (stream.active) {
      const track = stream.getVideoTracks()[0];
      videoTrackRef.current = track;

      setIsCameraReady(true);

      if (userMediaError) {
        setUserMediaError(undefined);
      }
    } else {
      setUserMediaError({
        name: "Stream Error",
        message: "Camera stream is not active.",
        constraint: "",
      });
    }
  };

  const onUserMediaError = (err: DOMException | string) => {
    let constraint = "";

    if (typeof err !== "string") {
      const { name, message } = err;

      if ("constraint" in err) {
        constraint = err["constraint"] as string;
      }

      setUserMediaError({ name, message, constraint });
    } else {
      setUserMediaError({ name: "", message: err, constraint });
    }
  };

  const onQuickPinchZoom = useCallback(
    ({ x, y, scale }: { x: number; y: number; scale: number }) => {
      const { current: cameraElement } = webcamRef;

      coordinatesRef.current = { x, y, scale };

      const coordinateX = x / scale;
      const coordinateY = y / scale;

      if (cameraElement) {
        const value = make3dTransformValue({ x: coordinateX, y: coordinateY, scale });
        transformValueRef.current = value;

        if (cameraElement.video) {
          cameraElement.video.style.setProperty("transform", value);
        }
      }

      setCurrentScale(scale);
    },
    [webcamRef],
  );

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (photoLimitVisible) {
      setTimeOut(() => {
        setPhotoLimitVisible(false);
      }, 2500);
    }
  }, [photoLimitVisible, setTimeOut]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && flash) {
      setTimeOut(() => {
        setFlash(false);
      }, 1000);
    }

    return () => {
      isMounted = false;
    };
  }, [flash, setTimeOut]);

  // Sets photo preview when you enter camera view
  // and there are already some photos taken.
  // Renders only once.
  useEffect(() => {
    if (!currentQuestionId) return;

    getPhotosFromIndexedDB(currentQuestionId).then((photosFromDB) => {
      if (photosFromDB && photosFromDB.length) {
        const lastIndex = photosFromDB.length - 1;
        const lastPhotoInCurrentAnswer = photosFromDB[lastIndex].src;

        setPhotoPreview({ src: lastPhotoInCurrentAnswer, count: photosFromDB.length });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !videoTrackRef.current ||
      !isMobile ||
      facingMode !== ENVIRONMENT ||
      !isCameraReady ||
      isIOs
    ) {
      return;
    }

    try {
      videoTrackRef.current.applyConstraints({
        advanced: [
          {
            torch: isTorchOn,
          },
        ],
      });
    } catch (err) {
      const message = `[Torch] - ${getCatchErrorMessage(err)}`;
      alert(message);
    }
  }, [isTorchOn, facingMode, isCameraReady, isIOs]);

  useEffect(() => {
    return () => {
      if (videoTrackRef.current) {
        videoTrackRef.current.stop();
      }
    };
  }, []);

  // --------------- Content ---------------

  content = createPortal(
    <Container>
      <CameraContainer flash={flash}>
        <ToolBar>
          {torchIconVisible && (
            <TorchToggle onClick={toggleTorch} isTorchOn={isTorchOn}>
              <TorchIcon className='torch-icon' />
            </TorchToggle>
          )}

          <Scale visible={scaleVisible}>
            {currentScale.toFixed(1)}
            <span className='x-sign'>X</span>
          </Scale>

          {isMobile && !userMediaError && (
            <RotateView>
              <RotateIcon className='icon rotate' onClick={toggleFacingMode} />
            </RotateView>
          )}
        </ToolBar>

        <QuickPinchZoom
          onUpdate={onQuickPinchZoom}
          doubleTapToggleZoom
          doubleTapZoomOutOnMaxScale
          onZoomStart={() => {
            setScaleVisible(true);
          }}
          onDragEnd={() => {
            setTimeOut(() => {
              setScaleVisible(false);
            }, 2500);
          }}
          onDoubleTap={() => {
            setScaleVisible(true);
            setTimeOut(() => {
              setScaleVisible(false);
            }, 2500);
          }}
        >
          <Webcam
            ref={webcamRef}
            height='100%'
            width='100%'
            screenshotFormat='image/jpeg'
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            onUserMediaError={onUserMediaError}
            screenshotQuality={screenshotQuality}
          />
        </QuickPinchZoom>

        {userMediaError && (
          <ErrorAlert
            userMediaError={userMediaError}
            onCloseButtonClick={onCloseButtonClick}
          />
        )}

        {photoLimitVisible && <PhotoLimitInfo />}

        {photoNotTaken && <PhotoNotTakenAlert />}

        {isCameraReady && !userMediaError && (
          <BottomPanel
            disabled={currentScale < 0.9}
            maxPhotos={maxPhotos}
            photoPreview={photoPreview}
            onPhotoPreviewClick={onPhotoPreviewClick}
            handleCloseCameraView={handleCloseCameraView}
            onTakePhotoCircleClick={onTakePhotoCircleClick}
          />
        )}
      </CameraContainer>
    </Container>,
    document.body,
  );

  return content;
}

export default WebcamCapture;
