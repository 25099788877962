import { AxiosProgressEvent } from "axios";
import { TaskPermissionCollections } from "./tasks.types";
import { CollectionEnum } from "./permissions.types";
import { Role } from "./roles.types";

export type Tokens = {
  access_token: string;
  refresh_token: string;
};

export type LoginFormPayload = {
  email: string;
  password: string;
};

export type LogoutPayload = {
  refresh_token: string;
};

export enum AuthMode {
  JSON = "json",
  COOKIE = "cookie",
  SESSION = "session",
}

export type LoginResponse = {
  data: {
    access_token: string;
    expires: number;
    refresh_token: string;
  };
};

export type RefreshSessionResponse = {
  data: {
    access_token: string;
    expires: number;
    refresh_token: string;
  };
};

export type QuestionType =
  | "yes_no"
  | "yes_no.input_text"
  | "yes_no.input_file_photo"
  | "input_text"
  | "input_number"
  | "input_file_photo"
  | "select";

export type QuestionObject = {
  id: string;
  sort: number;
  user_created: string;
  user_updated: string;
  date_created: Date | null;
  date_updated: Date | null;
  Questionnaire: string;
  AccidentForm: string | null;
  AllowNA: boolean | null;
  Options: string | null;
  QuestionType: QuestionType;
  QuestionText: string;
  QuestionSubtext: string;
  MaxPhotos: number | undefined | null;
  MaxText: number | undefined | null;
  Gravity: number;
  Required: boolean;
  RequiredMinText: number | null;
  RequiredMinPhotos: number | null;
};

export type QuestionnaireObject = {
  id: string;
  QuestionnaireTitle: string;
  QuestionnaireSubtitle: string;
  RandomOrder: boolean;
  Questions: QuestionObject[];
  last_assessment_date_created: Date | null;
  last_assessment: {
    File: string;
    date_created: Date;
  };
};

type Department = {
  id: number;
  Departments_id: {
    DepartmentName: string;
    id: string;
    Company: {
      id: string;
      CompanyName: string;
    };
  };
};

export enum MachineProcessStatuses {
  ARCHIVED = "archived",
  DRAFT = "draft",
  OUT_OF_ORDER = "out_of_order",
  PUBLISHED = "published",
}

export type MachineProcessStatus =
  | MachineProcessStatuses.ARCHIVED
  | MachineProcessStatuses.DRAFT
  | MachineProcessStatuses.OUT_OF_ORDER
  | MachineProcessStatuses.PUBLISHED;

export type Machine = {
  id: string;
  status: MachineProcessStatus;
  sort: number | null;
  user_created: string;
  date_created: Date;
  user_updated: string;
  date_updated: Date;
  SerialNo: string;
  InternalNo: number | null;
  MakeYear: number | null;
  HourMeter: number | null;
  HourMeterTime: string | null;
  Capacity: number;
  ServiceDate: Date | null;
  MachineModel: string;
  MachineType: string;
  Make: string;
  ServiceExpiryDate: string | Date | null;
  Misc: string | null;
};

export type Process = {
  id: string;
  status: MachineProcessStatus;
  sort: number | null;
  user_created: string;
  date_created: Date;
  user_updated: string;
  date_updated: Date;
  ProcessName: string;
  ProcessType: string;
  Address: string | null;
  Geolocation: string | null;
  Misc: string | null;
  SerialNo: string;
  ServiceExpiryDate: string | Date | null;
};

export type QuestionnairesResponse = {
  data: {
    machine: Machine;
    process: Process;
    questionnaires: QuestionnaireObject[];
  };
};

export type Orientation = "portrait" | "landscape";
export type FilesGallery = { src: string; comment: string }[];

export type Answer = {
  sort: number;
  AnswerCreated: Date;
  Gravity: number;
  OriginalText: string | null;
  OriginalSubText: string | null;
  Value: string | null;
  ValueSize: number;
  ReactionValue: string | null;
  ReactionSize: number;
  Question: string;
  QuestionType: QuestionType;
  FilesGallery: FilesGallery | null;
  Machine: string | null;
  Process: string | null;
};

export enum Answers {
  YES = "Yes",
  NO = "No",
  NA = "NA",
}

export type YesNoAnswer = Answers.YES | Answers.NO | Answers.NA;

export enum QUESTION_TYPE_IDS {
  YES_NO = "yes_no",
  YES_NO_TEXT = "yes_no.input_text",
  YES_NO_PHOTO = "yes_no.input_file_photo",
  TEXT = "input_text",
  NUMERIC = "input_number",
  FILE_PHOTO = "input_file_photo",
  SELECT = "select",
}

export type ModifiedAnswer = {
  sort: number;
  AnswerCreated: Date;
  Gravity: number;
  OriginalText: string | null;
  OriginalSubText: string | null;
  Value: string | null;
  ValueSize: number;
  ReactionValue: string | null;
  ReactionSize: number;
  Question: string;
  QuestionType: string;
  FilesGallery: FilesGallery | null;
};

export interface Assessment {
  AccidentForm: string | null;
  Payload: ModifiedAnswer[];
  Questionnaire: string | null;
  SerialNo: string;
  TotalAnswers: number;
  TotalGravity1Negatives: number;
  TotalNegatives: number;
  createdAt: Date;
}

export type QuestionnairePayload = {
  token: string;
  payload: {
    AccidentForm: string | null;
    Payload: ModifiedAnswer[];
    Questionnaire: string | null;
    SerialNo: string;
    TotalAnswers: number;
    TotalGravity1Negatives: number;
    TotalNegatives: number;
    createdAt: Date;
    AppVersion: string;
    Duration: number;
    Result: number;
    UserAgent: string;
    Machine: string | undefined;
    Process: string | undefined;
  };
  onUploadProgress: (e: AxiosProgressEvent) => void;
};

export type SendQuestionnaireResponse = {
  answers: string[];
  assessment: string;
};

export type AccidentFormPayload = {
  token: string;
  payload: Assessment;
  onUploadProgress: (e: AxiosProgressEvent) => void;
};

export type AccidentFormQuestion = {
  id: string;
  sort: number;
  user_created: string;
  user_updated: string;
  date_created: Date | null;
  date_updated: Date | null;
  Questionnaire: string;
  AccidentForm: string | null;
  QuestionType: QuestionType;
  QuestionText: string;
  QuestionSubtext: string;
  MaxPhotos: number | undefined | null;
  RequiredMinPhotos: number | null;
  RequiredMinText: number | null;
  Gravity: number;
  Required: boolean;
};

export type AccidentForm = {
  id: string;
  status: string;
  date_created: Date;
  date_updated: Date;
  RandomOrder: boolean;
  Title: string;
  Subtitle: string;
  IceEmail: string | null;
  IceSms: string | null;
  Questions: AccidentFormQuestion[];
  Colour: string | null;
  Department: {
    DepartmentName: string;
    id: string;
  };
};

export type AccidentFormResponse = {
  data: AccidentForm[];
};

type Avatar = {
  id: string;
  storage: string;
  filename_disk: string;
  filename_download: string;
  title: string;
  type: string;
  folder: string | null;
  uploaded_by: string;
  uploaded_on: Date | string;
  modified_by: string | null;
  modified_on: Date | string;
  charset: string | null;
  filesize: string;
  width: number;
  height: number;
  duration: number | null;
  embed: string | null;
  description: string | null;
  location: string | null;
  tags: string[] | null;
  metadata: any;
};

export type User = {
  id: string;
  avatar: Avatar | string | null;
  email: string;
  first_name: string;
  last_name: string;
  language: string | null;
  role: Role;
  Company: Company;
  Departments: Department[];
  Department: {
    id: string;
    DepartmentName: string;
    Company: {
      id: string;
      CompanyName: string;
    };
  };
};

export type UserInfoResponse = {
  data: User;
};

export type SelectedQuestionnaire = QuestionnaireObject | undefined;
export type Af = AccidentForm | undefined;

export type MachineProcess = {
  Machine: Machine | null;
  Process: Process | null;
};

export type AppSettings = {
  id: number;
  project_logo: string;
  project_name: string;
  MobileAppVersion: string;
  public_note: null;
  public_foreground: null;
  project_color: string;
  public_background: string;
  CookieConsent: string;
  CookieConsent_en: string;
  CookieConsent_pl: string;
};

export type AppSettingsResponse = {
  data: AppSettings;
};

export enum QuestionnaireStatuses {
  PUBLISHED = "published",
  IN_PROGRESS = "in_progress",
  RESOLVED = "resolved",
  ARCHIVED = "archived",
  DRAFT = "draft",
}

export type QuestionnaireStatus =
  | QuestionnaireStatuses.PUBLISHED
  | QuestionnaireStatuses.IN_PROGRESS
  | QuestionnaireStatuses.RESOLVED
  | QuestionnaireStatuses.ARCHIVED
  | QuestionnaireStatuses.DRAFT;

export type Company = {
  id: string;
  status: QuestionnaireStatus;
  user_created: string;
  date_created: Date;
  user_updated: string;
  date_updated: Date;
  VatNumber: string | null;
  AddressLine1: string | null;
  AddressLine2: string | null;
  PostCode: string | null;
  CompanyName: string | null;
  Logo: string | null;
  SerialNo_Title: string | null;
  GravityAlert_Operator: string | null;
  GravityAlert_Manager: string | null;
  GravityAlert_Leader: string | null;
  GravityAlert_Incognito: string | null;
  TermsAccept_Operator: string | null;
  TermsAccept_Manager: string | null;
  TermsAccept_Leader: string | null;
  TermsAccept_Incognito: string | null;
};

export type ComapaniesInfoResponse = {
  data: Company[];
};

export type TaskInProgress = {
  status: QuestionnaireStatuses.IN_PROGRESS;
  date_created: Date;
  OriginalText: string;
  Value: YesNoAnswer;
  ReactionValue: string | null;
  id: string;
  Assessment: {
    id: string;
    Questionnaire: {
      QuestionnaireTitle: string;
    } | null;
  };
  FilesGalleryOurs: {
    id: number;
    directus_files_id: {
      id: string;
      type: string;
      title: string;
      description: string;
    };
  }[];
};

export type TaskInProgressResponse = {
  data: TaskInProgress[];
};

export enum FormTypes {
  QUESTIONNAIRE = "questionnaire",
  ACCIDENT = "accident",
}

export type FormType = FormTypes.QUESTIONNAIRE | FormTypes.ACCIDENT;

export type Theme = "Dark" | "Light" | "Default";

export type UserAgent = {
  browser: {
    name: string;
    version: string;
  };
  os: {
    name: string;
    version: string;
    versionName: string;
  };
  platform: {
    type: string;
  };
  engine: {
    name: string;
  };
};

export interface DeviceInfo extends UserAgent {
  phoneModel: string;
  theme: Theme;
  app: "PWA" | "web app" | string;
  resolution: {
    width: number;
    height: number;
  };
  appWindowSize: {
    innerWidth: number;
    innerHeight: number;
  };
}

export enum Statuses {
  SUCCESS = "success",
  ERROR = "error",
}

export type Status = Statuses.SUCCESS | Statuses.ERROR;

export type ActionStatus = { status: Status };

export enum Stores {
  SerialNumbers = "serialNumbers",
  Photos = "photos",
  PhotosQuestionnaire = "photos-questionnaire",
  PhotosAccident = "photos-accident",
  PhotosTasks = "photos-tasks",
}

export enum EntitiesEnum {
  MACHINE = "Machine",
  PROCESS = "Process",
}

export type Entity = EntitiesEnum.MACHINE | EntitiesEnum.PROCESS | undefined;

export enum CountryCodes {
  PL = "pl",
  EN = "en",
  US = "en-US",
  GB = "en-GB",
}

export type CountryCode = CountryCodes.PL | CountryCodes.EN;

export type UserMediaError = {
  name: string;
  message: string;
  constraint: string;
};

export type Collection =
  | CollectionEnum.MACHINES
  | CollectionEnum.PROCESSES
  | TaskPermissionCollections;

export type ChangeEntityStatusPayload = {
  token: string;
  id: string;
  collection: Collection;
  status: MachineProcessStatus;
};

export type ChangeEntityStatusResponse = {
  data: {
    id: string;
    status: MachineProcessStatus;
    user_created: string;
    date_created: Date;
    user_updated: string;
    date_updated: string;
    Make: string;
    SerialNo: string;
    MakeYear: number;
    HourMeter: number;
    ServiceExpiryDate: Date | string;
    MachineModel: string;
    LastAssessment: string | null;
    Misc: string;
    Assessments: string[];
  };
};

export type AssignToUserPayload = {
  userId: string | null;
  assessmentId: string;
  token: string;
};

export type AssignToUserResponse = {
  data: {
    id: string;
    AssignTo: string | null;
  };
};

export type AddCommentToAssessmentPayload = {
  collection: "Assessments";
  item: string;
  comment: string;
  token: string;
};

export type AddCommentToAssessmentResponse = {
  data: {
    id: number;
    item: string;
  };
};

export type GetAssignedAssessmentsResponse = {
  data: {
    AssignTo: string;
    id: string;
    date_created: Date;
    user_created: {
      first_name: string;
      last_name: string;
      email: string;
      avatar: string | null;
    };
    Questionnaire: {
      QuestionnaireTitle: string;
    };
    status: QuestionnaireStatuses;
  }[];
};

export type GetSelectedAssessmentPayload = {
  token: string;
  assessmentId: string;
};

export type AssessmentPayloadItem = {
  sort: number;
  Gravity: number;
  date_created: Date;
  OriginalText: string;
  OriginalSubText: string;
  Value: YesNoAnswer;
  ValueSize: number;
  ReactionValue: string | null;
  ReactionSize: number;
  Question: string;
  QuestionType: QuestionType;
  FilesGallery: FilesGallery | null;
  Process: { ProcessName: string } | null;
  Machine: string | null;
};

export type AssessmentPayload = AssessmentPayloadItem[];

export type GetSelectedAssessmentResponse = {
  data: {
    id: string;
    Machine: { MachineModel: string; SerialNo: string } | null;
    Process: { ProcessName: string; SerialNo: string } | null;
    status: MachineProcessStatus;
    date_created: Date;
    Payload: AssessmentPayload;
    user_created: {
      first_name: string;
      last_name: string;
      avatar: string;
    } | null;
  }[];
};

export type PhotoObjectStoreItem = {
  FilesGallery: FilesGallery;
  Question: string;
  created: Date;
};

export type FilesGalleryRecord = Record<string, FilesGallery>;

export type AppVersion = {
  major: number;
  minor: number;
  patch: number;
};

export enum ErrorPosition {
  TOP_LEFT = "top_left",
  TOP_RIGHT = "top_right",
  BOTTOM_LEFT = "bottom_right",
}

export enum DeptStatus {
  ARCHIVED = "archived",
  PUBLISHED = "published",
}

export type DepartmentItem = {
  id: string;
  status: DeptStatus;
  sor: number;
  user_created: string;
  date_created: Date;
  user_updated: string;
  date_updated: Date;
  Company: string;
  DepartmentName: string;
};

export type DepartmentsResponse = {
  data: DepartmentItem[];
};

export type AssignDepartmentToUserPayload = {
  token: string;
  userId: string;
  Departments: {
    create: {
      directus_users_id: string;
      Departments_id: {
        id: string;
      };
    }[];
    update: number[];
    delete: number[];
  };
};

export type ImageSize = { width: number; height: number };

export type TextLength = number | null | undefined;
