import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import useScreen from "hooks/useScreen";
import { isMobile, PORTRAIT } from "utils/utils";

import { ContentContainer, Header } from "styles/generalStyles";

import { ReactComponent as CameraSwitchIcon } from "assets/icons/video-switch.svg";
import { ReactComponent as TorchIcon } from "assets/icons/flashlight.svg";
import BackArrow from "components/atoms/BackArrow";
import Button from "components/atoms/Button";

const CameraContainer = styled.div<{ isCameraReady: boolean }>`
  max-width: 45rem;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.greyDark};

  margin: 0 auto;

  #container-circles {
    visibility: ${({ isCameraReady }) => (!isCameraReady ? "hidden" : "visible")};
  }

  .display-error #white-flash.normal {
    display: none;
  }
`;

const CameraSwitch = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.3rem;
  z-index: ${({ theme }) => theme.level1};
  cursor: pointer;
`;

const PhotoContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  margin: 0 auto;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Actions = styled.div<{
  isPhotoTaken: boolean;
  isMobilePortrait: boolean;
  accidentView?: boolean;
}>`
  width: 100%;
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 30%;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: 4.5rem;
  left: 0;
  width: 100%;
  min-height: 10rem;
  border: 4px solid ${({ theme }) => theme.errorColor};
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.4rem;
  color: #fff;
  padding: 1rem;
  z-index: ${({ theme }) => theme.level2};

  .name {
    font-size: 1.4rem;
  }

  .message,
  .constraint {
    margin-top: 1rem;
  }

  .close-btn {
    float: right;
    padding: 0.5rem 1rem;
    font-family: GothamBook;
    background-color: ${({ theme }) => theme.greyLight};
    font-size: 1.4rem;
  }
`;

const TorchToggle = styled.div`
  position: absolute;
  right: -0.9rem;
  top: 0.3rem;
  z-index: 20;
  /* background-color: rgba(0, 0, 0, 0.7); */
  color: azure;
  display: grid;
  place-content: center;
  padding: 0 1rem;
  border-radius: 0.4rem;

  .torch-icon {
    width: 3rem;
    height: 3rem;
    fill: #fff;
  }
`;

const TorchNotSupported = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 3rem;
  border: 2px solid red;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.7);
  color: azure;
  display: grid;
  place-content: center;
  padding: 0 1rem;
  border-radius: 0.4rem;
`;

const { USER, ENVIRONMENT } = FACING_MODES;
const facingModeInitial = isMobile ? ENVIRONMENT : USER;

function ReactHtmlCameraPhoto() {
  const [imgSrc, setImgSrc] = useState("");
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const isPhotoTaken = !!imgSrc;
  const [facingMode, setFacingMode] = useState(facingModeInitial);
  const [userMediaError, setUserMediaError] = useState<Error>();
  const [isCameraReady, setIsCameraReady] = useState(false);

  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>();
  const [torchSupported, setTorchSupported] = useState<boolean | undefined>();
  const [isTorchOn, setIsTorchOn] = useState(false);

  const { orientation } = useScreen();
  const isMobilePortrait = isMobile && orientation === PORTRAIT;

  const navigate = useNavigate();

  const turnTorchOff = () => {
    if (isTorchOn) {
      setIsTorchOn(false);
    }
  };

  const onTakePhoto = (dataUri: string) => {
    setImgSrc(dataUri);
    turnTorchOff();
  };

  const removePhoto = () => {
    setImgSrc("");
  };

  const toggleFacingMode = () => {
    videoTrack?.stop();
    turnTorchOff();
    setIsCameraReady(false);

    if (facingMode === USER) {
      setFacingMode(ENVIRONMENT);
    } else {
      setFacingMode(USER);
    }
  };

  useEffect(() => {
    const videoHtmlCollection = document.getElementsByTagName("video");
    const video = [...videoHtmlCollection][0];

    if (video) {
      let width = 0;
      let height = 0;

      if (isMobilePortrait) {
        if (video) {
          width = video.clientWidth;
          height = video.clientHeight * 2;
        }
      } else {
        if (video) {
          width = video.clientWidth;
          height = video.clientHeight;
        }
      }

      setVideoSize({ height, width });
    }
  }, [isMobilePortrait]);

  const onCloseButtonClick = () => {
    window.location.reload();
  };

  // *************** Torch light ***************

  const log = (msg: string | unknown) => {
    console.log(msg);
  };

  const onCameraStart = () => {
    const SUPPORTS_MEDIA_DEVICES = "mediaDevices" in navigator;

    if (!SUPPORTS_MEDIA_DEVICES) return;

    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode,
        },
      })
      .then((stream) => {
        const track = stream.getVideoTracks()[0];

        setVideoTrack(track);
      });

    setIsCameraReady(true);
  };

  // useEffect(() => {
  //   if (!videoTrack) return;

  //   if ("ImageCapture" in window) {
  //     const imageCapture = new ImageCapture(videoTrack);

  //     imageCapture
  //       .getPhotoCapabilities()
  //       .then((capabilities) => {
  //         const isTorchSupported =
  //           "fillLightMode" in capabilities &&
  //           capabilities.fillLightMode.includes("flash");

  //         setTorchSupported(isTorchSupported);
  //       })
  //       .catch(() => {
  //         setTorchSupported(false);
  //       });
  //   } else {
  //     setTorchSupported(false);
  //   }
  // }, [videoTrack]);

  useEffect(() => {
    if (!videoTrack || !isMobile || facingMode !== ENVIRONMENT) return;

    try {
      videoTrack.applyConstraints({
        advanced: [
          {
            torch: isTorchOn,
          },
        ],
      });
    } catch (err) {
      log(err);
    }
  }, [isTorchOn, videoTrack, facingMode]);

  const toggleTorch = () => {
    setIsTorchOn((prev) => !prev);
  };

  return (
    <ContentContainer>
      <Header standardFont>
        <BackArrow
          onClick={() => {
            turnTorchOff();
            videoTrack?.stop();
            navigate(-1);
          }}
        />
        <span style={{ fontSize: "1.8rem" }}>react-html5-camera-photo</span>
      </Header>

      {isPhotoTaken && (
        <PhotoContainer width={videoSize.width} height={videoSize.height}>
          <Photo src={imgSrc} alt='' />
        </PhotoContainer>
      )}

      {!isPhotoTaken && (
        <CameraContainer isCameraReady={isCameraReady}>
          {isMobile && (
            <CameraSwitch onClick={toggleFacingMode}>
              <CameraSwitchIcon width='3rem' height='3rem' />
            </CameraSwitch>
          )}

          {/* {videoTrack && torchSupported !== undefined && torchSupported && (
            <TorchToggle onClick={toggleTorch}>
              <TorchIcon className='torch-icon' />
            </TorchToggle>
          )} */}

          {isMobile && facingMode === ENVIRONMENT && (
            <TorchToggle onClick={toggleTorch}>
              <TorchIcon className='torch-icon' />
            </TorchToggle>
          )}

          {videoTrack && torchSupported !== undefined && !torchSupported && (
            <TorchNotSupported>Flashlight not supported</TorchNotSupported>
          )}

          <Camera
            onTakePhoto={onTakePhoto}
            imageType={IMAGE_TYPES.JPG}
            idealFacingMode={facingMode}
            isImageMirror={false}
            isSilentMode
            isDisplayStartCameraError={false}
            onCameraError={(error: Error) => {
              console.log("🎃 ERROR", error.name);
              setUserMediaError({ name: error.name, message: error.message });
            }}
            onCameraStart={onCameraStart}
          />
        </CameraContainer>
      )}

      {userMediaError && (
        <ErrorContainer>
          {userMediaError?.name && <div className='name'>{userMediaError?.name}</div>}
          {userMediaError?.message && (
            <div className='message'>{userMediaError?.message}</div>
          )}
          <button className='close-btn' onClick={onCloseButtonClick}>
            ZAMKNIJ
          </button>
        </ErrorContainer>
      )}

      {isCameraReady && (
        <Actions isPhotoTaken={isPhotoTaken} isMobilePortrait={isMobilePortrait}>
          {isPhotoTaken && (
            <ButtonContainer>
              <Button label='Usuń' onClick={removePhoto} />
            </ButtonContainer>
          )}
        </Actions>
      )}
    </ContentContainer>
  );
}

export default ReactHtmlCameraPhoto;
