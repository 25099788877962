import { useTranslation } from "react-i18next";

import { TextLength } from "types/types";
import { getTranslations } from "translations/textarea.translations";

const getNumberValue = (value: TextLength) => (value ? value : 4096);

const getMaxTextLength = (minTextLength: TextLength, maxTextLength: TextLength) => {
  const minText = getNumberValue(minTextLength);
  const maxText = getNumberValue(maxTextLength);

  if (minText > maxText) {
    return minText;
  }

  return maxText;
};

function useTextLength(
  textValue: string | undefined,
  minTextLength: TextLength,
  maxTextLength: TextLength,
) {
  const {
    i18n: { language },
  } = useTranslation();
  const { error } = getTranslations(language);

  const minText = getNumberValue(minTextLength);
  const maxText = getMaxTextLength(minTextLength, maxTextLength);

  const textLength = textValue ? textValue.length : 0;
  const isTextTooShort = textLength < minText;
  const isTextTooLong = textLength > maxText;
  const isMinText = textLength >= minText;

  const errorMessage = isTextTooLong
    ? `${error.textTooLong} [${textLength}/${maxText}]`
    : "";

  return {
    textLength,
    minText,
    maxText,
    isTextTooShort,
    isTextTooLong,
    isMinText,
    errorMessage,
  };
}

export default useTextLength;
