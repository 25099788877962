import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Scanner } from "@yudiel/react-qr-scanner";
import { DetectedBarcode } from "barcode-detector";

import { device, reloadPage } from "utils/utils";
import { UserMediaError } from "types/types";
import useTimeout from "hooks/useTimeout";
import useBarcodeScannerAccess from "hooks/useBarcodeScannerAccess";
import { getTranslations } from "./translations/qr-scanner.translations";

import { ReactComponent as CameraOffIcon } from "assets/icons/video-camera_off.svg";
import { ReactComponent as BarcodeIcon } from "assets/icons/barcode-2.svg";

import Button from "components/atoms/Button";

const Container = styled.div`
  width: 90%;
  height: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  overflow: hidden;
  position: relative;

  @media ${device.iPad} {
    width: 75%;
    margin-top: 5rem;
  }

  path {
    stroke: ${({ theme }) => theme.primary_50};
  }
`;

const ScannerContainer = styled.div``;

const BarcodeHeader = styled.div`
  position: absolute;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(00, 0, 0, 0.5);
  color: ${({ theme }) => theme.white};
  z-index: ${({ theme }) => theme.level1};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  .barcode-icon {
    width: 2rem;
    height: 2rem;
  }
`;

const WarningContainer = styled.div`
  ${({ theme }) => theme.fillUpRemainingSpace}
  justify-content: space-between;
`;

const Warning = styled.div`
  border: 6px solid ${({ theme }) => theme.errorColor};
  border-radius: 0.4rem;

  padding-top: 2.5rem;
  padding-right: 2rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;

  background-color: rgba(0, 0, 0, 0.9);
  color: ${({ theme }) => theme.white};
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.graphiteGreyLight};

  .camera-off-icon {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.white};
  }
`;

const WarningActions = styled.div`
  padding-top: 2.5rem;
  display: flex;
  justify-content: flex-end;

  .refresh-btn {
    height: 3.5rem;
    width: 10rem;
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.primary_100};
    color: ${({ theme }) => theme.primary};
  }
`;

const Bottom = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 1.25rem;
  text-align: center;
  font-size: 1.4rem;
`;

type QrScannerProps = {
  onScan: (detectedCodes: DetectedBarcode[]) => void;
  isCameraReady: boolean;
  setIsCameraReady: Dispatch<SetStateAction<boolean>>;
  setClickAllowed?: Dispatch<SetStateAction<boolean>>;
  barcode?: boolean;
};

function QrScanner({
  onScan,
  isCameraReady,
  setIsCameraReady,
  setClickAllowed,
  barcode = false,
}: QrScannerProps) {
  const [userMediaError, setUserMediaError] = useState<UserMediaError>();
  const [showScanner, setShowScanner] = useState(false);
  const videoTrackRef = useRef<MediaStreamTrack>();
  const containerRef = useRef<HTMLDivElement>(null);
  const setTimeOut = useTimeout();
  const {
    i18n: { language },
  } = useTranslation();
  const { message, button } = getTranslations(language);

  const { formats } = useBarcodeScannerAccess();

  let scannerProps = {
    onScan,
    components: { torch: false, audio: false },
    constraints: { facingMode: "environment" },
    scanDelay: 1000,
  } as BarcodeDetectorOptions;

  if (formats.length) {
    scannerProps = { ...scannerProps, formats };
  }

  const onUserMediaError = (err: DOMException | string) => {
    let constraint = "";

    if (typeof err !== "string") {
      const { name, message } = err;

      if ("constraint" in err) {
        constraint = err["constraint"] as string;
      }

      setUserMediaError({ name, message, constraint });
    } else {
      setUserMediaError({ name: "", message: err, constraint });
    }
  };

  const onUserMedia = (stream: MediaStream) => {
    if (stream.active) {
      const track = stream.getVideoTracks()[0];
      videoTrackRef.current = track;

      setIsCameraReady(true);
    } else {
      setUserMediaError({
        name: "Stream Error",
        message: "Camera stream is not active.",
        constraint: "",
      });
    }
  };

  const onRefreshBtnClick = () => {
    reloadPage();
  };

  useEffect(() => {
    if (isCameraReady) {
      if (videoTrackRef.current) {
        videoTrackRef.current.stop();
      }

      setTimeOut(() => {
        setShowScanner(true);
      }, 50);
    }
  }, [isCameraReady, setTimeOut]);

  useEffect(() => {
    if (!!userMediaError) {
      if (setClickAllowed) {
        setClickAllowed(true);
      }
    }
  }, [userMediaError, setClickAllowed]);

  useEffect(() => {
    return () => {
      setIsCameraReady(false);
    };
  }, [setIsCameraReady]);

  useEffect(() => {
    if (!showScanner || !barcode || !containerRef.current) return;

    const svg = containerRef.current.getElementsByTagName("svg")[0];

    if (svg) {
      svg.remove();
    }
  }, [showScanner, barcode]);

  return (
    <Container>
      {barcode && showScanner && (
        <BarcodeHeader>
          <BarcodeIcon className='barcode-icon' />
          <span>{message.scanBarcode}</span>
        </BarcodeHeader>
      )}
      {showScanner && (
        <ScannerContainer ref={containerRef}>
          <Scanner {...scannerProps} />
        </ScannerContainer>
      )}

      {userMediaError && (
        <WarningContainer>
          <Warning>
            <Top>
              <CameraOffIcon className='camera-off-icon' />
              <span>{message.top}</span>
            </Top>
            <Bottom>{message.bottom}</Bottom>
            <WarningActions>
              <Button
                label={button.label}
                customClass='refresh-btn'
                onClick={onRefreshBtnClick}
              />
            </WarningActions>
          </Warning>
        </WarningContainer>
      )}

      {!showScanner && (
        // This is used to catch errors because
        // @yudiel/react-qr-scanner does not have
        // an error handler.
        <Webcam
          style={{ visibility: "hidden" }}
          onUserMediaError={onUserMediaError}
          onUserMedia={onUserMedia}
        />
      )}
    </Container>
  );
}

export default QrScanner;
