import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { TypeOptions } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DetectedBarcode } from "barcode-detector";

import {
  isProperLoginUrl,
  getPathFromUrl,
  getLabelTranslation,
  convertToBoolean,
} from "../../utils/utils";
import { isMobile } from "utils/utils";
import useNotification from "hooks/useNotification";
import { ScanMethod } from "../../types/login.types";

import { ReactComponent as SmartphoneIcon } from "assets/icons/smartphone.svg";
import { ReactComponent as QrCodeIcon } from "assets/icons/qr-code-4.svg";
import { ReactComponent as BarCodeScannerIcon } from "assets/icons/barcode-scanner.svg";

import SnapCheckTitle from "components/atoms/logos/SnapCheckTitle";
import QrScanner from "components/organisms/qr-scanner/QrScanner";
import BackArrow from "components/atoms/BackArrow";

import { ContentContainer } from "styles/generalStyles";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  .qr-icon {
    width: 5rem;
    fill: ${({ theme }) => theme.primary};
    display: none;

    @media screen and (min-height: 525px) {
      display: block;
    }
  }

  .title {
    color: ${({ theme }) => theme.primary};
    font-size: 1.6rem;
    font-family: GothamBold;

    @media screen and (min-width: 360px) {
      font-size: 2rem;
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;

  .back-icon {
    height: 100%;
  }
`;

const ScanInputContainer = styled.div`
  position: relative;
  padding: 0.5rem 0;
  width: 65%;
  margin: 2rem auto;
  border-radius: 0.4rem;

  .contour-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  .contour:before {
    content: "";
    z-index: ${({ theme }) => theme.level1};
    position: absolute;
    height: 200%;
    width: 200%;
    top: -120%;
    left: -120%;
    background: linear-gradient(
      transparent 0%,
      rgba(122, 140, 177, 0.1) 45%,
      rgba(122, 140, 177, 0.1) 50%,
      rgba(122, 140, 177, 0.1) 55%,
      transparent 100%
    );
    transition: all 2s;
    transform: rotate(-45deg);
    animation: shine 2s infinite forwards;
  }

  @keyframes shine {
    0% {
      top: -120%;
      left: -120%;
    }
    100% {
      left: 100%;
      top: 100%;
    }
  }

  .contour {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-repeat: no-repeat;
    mask-size: cover;
  }
`;

const ScanInputLabel = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  z-index: ${({ theme }) => theme.level2};
  text-align: center;
  text-transform: uppercase;
`;

const ScanInput = styled.input`
  opacity: 0;
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const IconContainer = styled.div<{ isMobile: boolean; isActive: boolean }>`
  border: 1px solid;
  padding: 0.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(196, 205, 222, 0.25);
  cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

  ${({ isActive }) =>
    !isActive &&
    css`
      ${({ theme }) => theme.buttonShadow};
      opacity: 0.5;
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.primary_100};
    `}


  &:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  &:nth-child(2) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .scan-icon {
    width: 3rem;
    height: 3rem;
  }
`;

const { CAMERA, LIGHT } = ScanMethod;

function LoginScanner() {
  const [scannedData, setScannedData] = useState<string>();
  const [inputValue, setInputValue] = useState("");
  const [isCameraReady, setIsCameraReady] = useState(false);
  // Prevents from getting error when switching between methods.
  // If camera is not fully ready switching to light scanner may cause camera errors.
  const [clickAllowed, setClickAllowed] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation("login");
  const { label } = getLabelTranslation(language);
  const notify = useNotification();
  const hasVideoInput = convertToBoolean(searchParams.get("video-input"));

  const currentTab = searchParams.get("method") as ScanMethod;

  // --------------- UI handlers ---------------

  const displayAlert = useCallback(
    (message: string, type: TypeOptions) => {
      notify(message, type);
    },
    [notify],
  );

  const displayWarningAlert = useCallback(() => {
    displayAlert(t("login-with-qr.error.invalid-code"), "warning");
  }, [displayAlert, t]);

  const foncusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // --------------- On click handlers ---------------

  const onBackArrowClick = () => {
    if (currentTab === CAMERA && !clickAllowed) return;
    navigate("/login", { replace: true });
  };

  const onTabClick = (method: ScanMethod) => () => {
    localStorage.setItem("scan-method", method);

    setSearchParams(
      { "video-input": searchParams.get("video-input") as string, method },
      { replace: true },
    );
  };

  // --------------- Event handlers ---------------

  const onScan = (result: DetectedBarcode[]) => {
    const { rawValue } = result[0];
    setScannedData(rawValue);
  };

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
  };

  const onBlur = () => {
    foncusInput();
  };

  // --------------- Code handlers ---------------

  const handleLoginWithQrCode = useCallback(
    (path: string) => {
      navigate(path, { replace: true });
    },
    [navigate],
  );

  const handleUrl = useCallback(
    (url: string) => {
      if (isProperLoginUrl(url)) {
        const path = getPathFromUrl(url);
        handleLoginWithQrCode(path);
      } else {
        displayWarningAlert();
      }
    },
    [displayWarningAlert, handleLoginWithQrCode],
  );

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (scannedData) {
      handleUrl(scannedData);
    }
  }, [scannedData, handleUrl]);

  useEffect(() => {
    if (currentTab === LIGHT) {
      foncusInput();
    }

    if (currentTab === CAMERA) {
      setClickAllowed(false);
    }
  }, [currentTab]);

  useEffect(() => {
    if (!inputValue) return;

    const timer = setTimeout(() => {
      handleUrl(inputValue);
    }, 500);

    return () => clearTimeout(timer);
  }, [inputValue, handleUrl]);

  useEffect(() => {
    if (!isCameraReady) return;

    const timer = setTimeout(() => {
      setClickAllowed(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isCameraReady]);

  return (
    <ContentContainer>
      <SnapCheckTitle />

      <Header>
        <QrCodeIcon className='qr-icon' />
        <TitleContainer>
          <BackArrow onClick={onBackArrowClick} customClass='back-icon' />
          <span className='title'>{t("login-with-qr.header")}</span>
        </TitleContainer>

        {hasVideoInput && (
          <IconsContainer>
            <IconContainer
              isActive={currentTab === CAMERA}
              isMobile={isMobile}
              onClick={onTabClick(CAMERA)}
            >
              <SmartphoneIcon className='scan-icon' />
            </IconContainer>
            <IconContainer
              isActive={currentTab === LIGHT}
              isMobile={isMobile}
              onClick={clickAllowed ? onTabClick(LIGHT) : () => {}}
            >
              <BarCodeScannerIcon className='scan-icon' />
            </IconContainer>
          </IconsContainer>
        )}
      </Header>

      {currentTab === CAMERA && (
        <QrScanner
          isCameraReady={isCameraReady}
          setIsCameraReady={setIsCameraReady}
          setClickAllowed={setClickAllowed}
          onScan={onScan}
        />
      )}
      {currentTab === LIGHT && (
        <ScanInputContainer>
          <ScanInputLabel>&#187;&nbsp;{label}&nbsp;&#171;</ScanInputLabel>

          <div className='contour-wrap'>
            <div className='contour'>&nbsp;</div>
          </div>

          <ScanInput
            autoComplete='off'
            name='scanInput'
            onChange={onChange}
            value={inputValue}
            ref={inputRef}
            onBlur={onBlur}
          />
        </ScanInputContainer>
      )}
    </ContentContainer>
  );
}

export default LoginScanner;
