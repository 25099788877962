import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { ReactComponent as DoneIcon } from "assets/icons/done-v.svg";
import TypingLoader from "components/atoms/loaders/TypingLoader";
import { CountryCodes } from "types/types";

const Container = styled.div`
  position: absolute;
  top: -2rem;
  left: 0;
`;

const LabelContainer = styled.div<{ saved?: boolean; loader?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  opacity: 0.3;

  ${({ loader }) =>
    loader &&
    css`
      transform: translateY(0.5rem);
    `}

  ${({ saved }) =>
    saved &&
    css`
      opacity: 0.75;
    `}
`;

const IconContainer = styled.span`
  .icon {
    width: 1.5rem;
    height: 1.5rem;
    transform: translateY(0.1rem);
  }
`;

const Label = styled.span`
  font-size: 1.4rem;
`;

type AutoSaveLabelProps = {
  saved: boolean;
  isTyping: boolean;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let label = "Changes saved!";

  if (isPolish) {
    label = "Zapisano zmiany!";
  }

  return label;
};

function AutoSaveLabel({ saved, isTyping }: AutoSaveLabelProps) {
  const {
    i18n: { language },
  } = useTranslation();

  const label = getTranslations(language);

  return (
    <Container>
      {saved && !isTyping && (
        <LabelContainer saved>
          <IconContainer>
            <DoneIcon className='icon' />
          </IconContainer>
          <Label>{label}</Label>
        </LabelContainer>
      )}
      {isTyping && (
        <LabelContainer loader>
          <TypingLoader />
        </LabelContainer>
      )}
    </Container>
  );
}

export default AutoSaveLabel;
