import styled from "styled-components/macro";

const Container = styled.div<{ ease: string }>`
  position: absolute;
  top: 50%;
  right: 0rem;
  transform: translateY(-50%);
  width: 7rem;

  .svg-arrows {
    width: 2.5rem;
    height: auto;
    overflow: visible;

    .arrow,
    .arrow-fixed {
      transition: all 1s ${({ ease }) => ease};
      fill: ${({ theme }) => theme.white};
      stroke: ${({ theme }) => theme.white};
    }

    .arrow {
      animation: arrow-anim 2.5s ${({ ease }) => ease} infinite;
    }
    .arrow-fixed {
      animation: arrow-fixed-anim 2.5s ${({ ease }) => ease} infinite;
    }
  }

  @keyframes arrow-anim {
    0% {
      opacity: 1;
      transform: translateX(0);
    }
    5% {
      transform: translateX(-0.1rem);
    }
    100% {
      transform: translateX(1.25rem);
      opacity: 0;
    }
  }

  @keyframes arrow-fixed-anim {
    5% {
      opacity: 0;
    }
    20% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

function AnimatedArrow({ customClass = "" }: { customClass?: string }) {
  const ease = "cubic-bezier(0.2, 1, 0.3, 1)";

  return (
    <Container ease={ease} className={customClass}>
      <svg
        viewBox='0 0 20 15'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        className='svg-arrows'
      >
        <g>
          <polygon
            className='arrow'
            points='16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596'
          ></polygon>
          <polygon
            className='arrow-fixed'
            points='16.3746667 8.33860465 7.76133333 15.3067621 6.904 14.3175671 14.2906667 8.34246869 6.908 2.42790698 7.76 1.43613596'
          ></polygon>
        </g>
      </svg>
    </Container>
  );
}

export default AnimatedArrow;
