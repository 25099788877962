import styled from "styled-components/macro";

import MissingAlert from "../missing-alert/MissingAlert";

const Container = styled.div`
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .number-of-images {
    font-family: GothamBold;
  }

  .image {
    height: 3rem;
    border-radius: 0.2rem;
  }
`;

const PhotoPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

type PhotosInfoProps = {
  minPhotos: number;
  numberOfPhotos: number;
  photoPreview: string;
  labels: { photos: string; missing: string };
  required: boolean;
};

function PhotosInfo({
  minPhotos,
  numberOfPhotos,
  photoPreview,
  labels,
  required,
}: PhotosInfoProps) {
  const minPhotosNumber = minPhotos;
  const missingPhotosNumber = minPhotosNumber - numberOfPhotos;
  const { photos, missing } = labels;

  return (
    <Container>
      <PhotoPreview>
        <img src={photoPreview} className='image' alt='' />
        <span>
          <span>{photos}:</span>
          <span className='number-of-images'>&nbsp;{numberOfPhotos}</span>
        </span>
      </PhotoPreview>

      {missingPhotosNumber > 0 && required && (
        <MissingAlert label={`${missing}: ${missingPhotosNumber}`} />
      )}
    </Container>
  );
}

export default PhotosInfo;
