import {
  Answer,
  Answers,
  FormTypes,
  QuestionnaireObject,
  QUESTION_TYPE_IDS,
  FilesGalleryRecord,
  QuestionObject,
} from "types/types";
import usePhotosForAnswers from "./usePhotosForAnswers";
import { getMinMaxPhotos } from "pages/questionnaire/utils/utils";

const { QUESTIONNAIRE } = FormTypes;
const { FILE_PHOTO, YES_NO_PHOTO, YES_NO_TEXT } = QUESTION_TYPE_IDS;
const { NO } = Answers;

const getRequiredQuestions = (questionnaire: QuestionnaireObject) => {
  if (questionnaire) {
    return questionnaire.Questions.filter(({ Required }) => !!Required);
  }
};

// ----- Answers with missing photos handlers -----

const getAnswersWithPhotos = (answer: Answer) =>
  answer.QuestionType === FILE_PHOTO ||
  (answer.QuestionType === YES_NO_PHOTO && answer.Value === NO)
    ? answer
    : undefined;

const getAnswersWithMissingPhotos = (
  requiredQuestions: QuestionObject[],
  answers: Answer[],
  photosForAnswers: FilesGalleryRecord | undefined,
) =>
  !!photosForAnswers
    ? answers
        .filter(getAnswersWithPhotos)
        .filter((answer) => !!answer)
        .reduce((acc: Answer[], curr) => {
          const currentQuestion = requiredQuestions.find(
            ({ id }) => id === curr.Question,
          );
          const { minPhotos } = getMinMaxPhotos(currentQuestion);
          const photosTaken =
            photosForAnswers && photosForAnswers[curr.Question]
              ? photosForAnswers[curr.Question].length
              : 0;

          if (minPhotos > photosTaken) {
            acc = [...acc, curr];
          }

          return acc;
        }, [])
    : [];

// ----- Answers with missing description handlers -----

const getAnswersWithMissingDescription = (
  answers: Answer[],
  requiredQuestions: QuestionObject[],
) =>
  answers.filter(
    ({ QuestionType, ReactionValue, Value, Question }) =>
      QuestionType === YES_NO_TEXT &&
      Value === NO &&
      requiredQuestions.some(({ id }) => id === Question) &&
      !ReactionValue,
  );

// --------------- ***** ---------------

function useRequiredQuestions(answers: Answer[], questionnaire: QuestionnaireObject) {
  const requiredQuestions = getRequiredQuestions(questionnaire) ?? [];
  const { photosForAnswers } = usePhotosForAnswers(QUESTIONNAIRE);

  // ***** Required questions *****

  let allRequiredQuestionsAnswered = true;
  let requiredQuestionsUnanswered: string[] = [];

  let answersWithMissingPhotosNumber = 0;
  let answersWithMissingDescriptionNumber = 0;

  let firstUnansweredQuestionIndex = -1;
  let firstQuestionWithMissingPhotosIndex = -1;
  let firstQuestionWithMissingDescriptionIndex = -1;

  if (requiredQuestions.length) {
    const requiredQuestionsAnswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (answers.some(({ Question }) => Question === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    requiredQuestionsUnanswered = requiredQuestions.reduce((acc: string[], curr) => {
      if (!requiredQuestionsAnswered.some((id) => id === curr.id)) {
        acc = [...acc, curr.id];
      }

      return acc;
    }, []);

    firstUnansweredQuestionIndex = questionnaire.Questions.findIndex(
      ({ id }) => id === requiredQuestionsUnanswered[0],
    );

    allRequiredQuestionsAnswered = !requiredQuestionsUnanswered.length;

    // ***** Answers with missing photos *****

    const answersWithMissingPhotos = getAnswersWithMissingPhotos(
      requiredQuestions,
      answers,
      photosForAnswers,
    );

    answersWithMissingPhotosNumber = answersWithMissingPhotos.length;

    if (answersWithMissingPhotosNumber) {
      firstQuestionWithMissingPhotosIndex = questionnaire.Questions.findIndex(
        ({ id }) => id === answersWithMissingPhotos[0].Question,
      );
    }

    // ***** Answers with missing description *****

    const answersWithMissingDescription = getAnswersWithMissingDescription(
      answers,
      requiredQuestions,
    );
    answersWithMissingDescriptionNumber = answersWithMissingDescription.length;

    if (answersWithMissingDescriptionNumber) {
      firstQuestionWithMissingDescriptionIndex = questionnaire.Questions.findIndex(
        ({ id }) => id === answersWithMissingDescription[0].Question,
      );
    }
  }

  const indexes = [
    firstUnansweredQuestionIndex,
    firstQuestionWithMissingPhotosIndex,
    firstQuestionWithMissingDescriptionIndex,
  ].filter((index) => index >= 0);

  // This is to prevent goToIndex from getting value of Infinity
  const goToIndex = indexes.length ? Math.min(...indexes) : 0;

  return {
    allRequiredQuestionsAnswered,
    answersWithMissingPhotosNumber,
    answersWithMissingDescriptionNumber,
    goToIndex,
  };
}

export default useRequiredQuestions;
