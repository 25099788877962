import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import useAccidentForm from "hooks/useAccidentForm";
import useScreen from "hooks/useScreen";
import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";

import { ReactComponent as ChevronIcon } from "assets/icons/navigation-chevron.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";

import {
  ArrowsContainer,
  Arrow,
  QuestionNumber,
  Header,
  NoQuestions,
  QuestionNumberContainer,
} from "../../questionnaire/styles/questionnaireStyles";

type NavHeaderProps = {
  onBackClick: () => void;
  onForwardClick: () => void;
};

function NavHeader({ onBackClick, onForwardClick }: NavHeaderProps) {
  const {
    state: { selectedAccidentForm },
  } = useContext(QuestionnairesContext);
  const { currentQuestionIndex, numberOfQuestions, previewMode } = useAccidentForm();
  const { t } = useTranslation("alarms");
  const [searchParams] = useSearchParams();
  const isListening = !!searchParams.get("listening");

  const { accidentView } = useScreen();

  const handleForwardClick = () => {
    onForwardClick();
  };

  const handleBackClick = () => {
    if (isListening) return;
    onBackClick();
  };

  /* currentQuestionIndex !== -1 prevents header from flickering
     when index changes from 0 to value from summary  */
  return selectedAccidentForm && currentQuestionIndex !== -1 ? (
    <Header accidentView={accidentView}>
      <ArrowsContainer>
        <Arrow
          onClick={handleBackClick}
          className='back'
          isHidden={currentQuestionIndex === 0}
          isBlocked={isListening}
        >
          <ChevronIcon className='chevron-icon' />
        </Arrow>
      </ArrowsContainer>

      <QuestionNumberContainer>
        {!!numberOfQuestions ? (
          <QuestionNumber accident>
            {currentQuestionIndex + 1}&#x2f;{numberOfQuestions}
          </QuestionNumber>
        ) : (
          <NoQuestions>
            <WarningIcon className='warning-icon' />
            <span>{t("empty-form")}</span>
          </NoQuestions>
        )}
      </QuestionNumberContainer>

      <ArrowsContainer forward>
        {previewMode && (
          <Arrow onClick={handleForwardClick} className='forward'>
            <ChevronIcon className='chevron-icon' />
          </Arrow>
        )}
      </ArrowsContainer>
    </Header>
  ) : null;
}

export default NavHeader;
