import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useLogout from "hooks/useLogout";
import { getCommonTranslations } from "translations/common.translations";

import ConfirmationModal from "components/organisms/ConfirmationModal";

import { ContentContainer } from "styles/generalStyles";

function LogoutPage() {
  const navigate = useNavigate();
  const { handleLogOut } = useLogout();

  const {
    i18n: { language },
  } = useTranslation();
  const {
    labels: { yes, no },
    modalMessage,
  } = getCommonTranslations(language);

  const onYesClick = () => {
    handleLogOut();
  };

  const onNoClick = () => {
    navigate("/home", { replace: true });
  };

  return (
    <ContentContainer>
      <ConfirmationModal
        open
        message={modalMessage.signout}
        onClick={onYesClick}
        onNoClick={onNoClick}
        buttonLabel={yes}
        noButtonLabel={no}
        buttonMiddle
      />
    </ContentContainer>
  );
}

export default LogoutPage;
