import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SelectedQuestionnaire } from "types/types";

const shouldNavigateToHomePage = (
  selectedQuestionnaire: SelectedQuestionnaire,
  pathname: string,
) =>
  !selectedQuestionnaire &&
  ["/additional-options", "/questionnaire"].some((path) => pathname.includes(path));

// *****
// This hook handles the case when user has been redirected to Home page
// after sending a report and clicks on the back arrow on their phone
// or in the browser.
// *****

function useBackArrowHandler(
  selectedQuestionnaire: SelectedQuestionnaire,
  pathname: string,
) {
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldNavigateToHomePage(selectedQuestionnaire, pathname)) {
      navigate("/logout", { replace: true });
    }
  }, [selectedQuestionnaire, pathname, navigate]);
}

export default useBackArrowHandler;
